import React, {useState, useEffect, useCallback, useMemo} from 'react'
import PropTypes from 'prop-types';

// Context
import {useDrawer} from '../../context/DrawerContext';

// Custom components
import CommonPageWrapper from '../../components/CommonPageWrapper';
import CommentComposer from '../../components/report-workspace/CommentComposer';
import CommentDrawer from '../../components/report-workspace/CommentDrawer';
import CommentSelectionMenu from '../../components/report-workspace/CommentSelectionMenu';
import DocumentToolbar from '../../components/report-workspace/DocumentToolbar';
import HistoryDrawer from '../../components/report-workspace/DocumentHistory/HistoryDrawer';
import Loading from '../../components/Loading';

// Tiptap
import {mergeAttributes} from '@tiptap/core'
import StarterKit from '@tiptap/starter-kit'
import Heading from '@tiptap/extension-heading';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import UniqueID from '@tiptap-pro/extension-unique-id';
import {EditorContent, PureEditorContent, useEditor} from '@tiptap/react';
import {Indent} from '../../components/report-workspace/Indent';
import {PageExtension} from '../../components/report-workspace/PageExtension';
import {PagedDocument} from '../../components/report-workspace/PagedDocument';
import LinkedTextNodeView from '../../components/report-workspace/LinkedTextNodeView';
import {DocumentPasteHandler} from '../../components/report-workspace/DocumentPasteHandler';
import DocumentTableNodeView from '../../components/report-workspace/DocumentTable/DocumentTableNodeView';

// Collaboration
import Collaboration from '@tiptap/extension-collaboration'
import CollaborationHistory, {UserHistoryExtension} from '@tiptap-pro/extension-collaboration-history'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import {useSelf} from '../../liveblocks.config';
import {LiveblocksCommentsHighlight} from '../../components/report-workspace/CommentHighlight';
import {insertYDocUserMeta} from '../../components/report-workspace/DocumentHistory/utils';

// MUI
import {
  Box,
  Stack,
  useTheme,
} from '@mui/material';

// Utils
import {COPILOT_DRAWER_WIDTH} from '../../App';
import {debounce} from 'lodash';

// https://github.com/ueberdosis/tiptap/issues/4492
PureEditorContent.prototype.maybeFlushSync = (fn: () => void) => {
  fn();
};

const DocumentEditor = React.memo(({
  doc,
  provider,
  canEdit,
  content,
  contentToPrintRef,
  editorRef,
  enterPrintMode,
  isPrinting,
  isSaving,
  onPrintContentReady,
  saveDocument,
  flags,
}) => {
  const [isTableActive, setIsTableActive] = useState(false);
  const [selectionCount, setSelectionCount] = useState(0);

  const {drawerStates, isDrawerOpen, openDrawer, closeDrawer} = useDrawer();

  // History state
  const [isAutoVersioning, setIsAutoVersioning] = useState(false);
  const [historyDataFetched, setHistoryDataFetched] = useState(false);
  const [versions, setVersions] = useState([]);
  const [isLoadingVersionPreview, setIsLoadingVersionPreview] = useState(false);
  const [storedVersionContent, setStoredVersionContent] = useState('');
  const [storedDiffContent, setStoredDiffContent] = useState(null);

  const {name, color} = useSelf((me) => me.info);
  const theme = useTheme();

  const debouncedSaveDocument = useCallback(
    debounce(() => {
      if (canEdit && editorRef.current) {
        saveDocument(editorRef.current);
      }
    }, 1000),
    [canEdit],
  );

  const triggerSaveForReadOnly = useCallback(() => {
    if (!canEdit && editorRef.current) {
      saveDocument(editorRef.current);
    }
  }, [canEdit]);

  const editorExtensions = useMemo(() => {
    const baseExtensions = [
      PagedDocument,
      PageExtension.configure({
        bodyPadding: 46,
        bodyWidth: 816,
        headerHeight: 0,
        footerHeight: 0,
        bodyHeight: 1056,
        isPaging: true,
      }),
      DocumentTableNodeView.configure({
        isPrinting,
      }),
      LinkedTextNodeView.configure({
        isPrinting,
      }),
      DocumentPasteHandler,
      StarterKit.configure({
        document: false,
        heading: false,
        history: !flags.collaborativeDocuments,
      }),
      Heading.extend({
        levels: [1, 2, 3],
        renderHTML ({node, HTMLAttributes}) {
          const level = this.options.levels.includes(node.attrs.level)
            ? node.attrs.level
            : this.options.levels[0];

          const classes = {
            1: 'text-huge',
            2: 'text-large',
            3: 'text-small',
          };

          return [
            `h${level}`,
            mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
              class: `${classes[level]}`,
            }),
            0,
          ];
        },
      }).configure({levels: [1, 2, 3]}),
      Underline,
      Indent,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'right', 'center'],
      }),
      LiveblocksCommentsHighlight.configure({
        HTMLAttributes: {
          class: 'comment-highlight',
        },
      }),
      UniqueID.configure({
        types: ['heading', 'paragraph', 'orderedList', 'bulletList', 'listItem', 'table', 'horizontalRule', 'linkedText'],
      }),
    ];

    if (flags.collaborativeDocuments) {
      return [
        ...baseExtensions,
        Collaboration.configure({
          document: doc,
        }),
        CollaborationHistory.configure({
          provider,
          onUpdate: data => {
            setVersions(data.versions);
            setIsAutoVersioning(data.versioningEnabled);
            setHistoryDataFetched(true);
          },
        }),
        UserHistoryExtension.configure({
          provider,
        }),
        CollaborationCursor.configure({
          provider,
          user: {
            name,
            color,
          },
        }),
      ];
    }

    return baseExtensions;
  }, [flags.collaborativeDocuments, isPrinting, doc, provider, name, color]);

  let editor;
  let versionPreviewEditor;
  if (flags.collaborativeDocuments) {
    editor = useEditor({
      extensions: editorExtensions,
      editable: canEdit,
      shouldRerenderOnTransaction: false,
      onSelectionUpdate: ({editor}) => {
        if (!editor.state.selection.empty) {
          setSelectionCount(prev => prev + 1);
        }
      },
    }, [isPrinting]);

    const versionPreviewExtensions = editorExtensions.filter(
      (extension) =>
        extension.name !== 'collaboration' &&
        extension.name !== 'collaborationCursor' &&
        extension.name !== 'collaborationHistory' &&
        extension.name !== 'commentHighlight',
    );

    versionPreviewEditor = useEditor({
      extensions: versionPreviewExtensions,
      editable: false,
      content: storedVersionContent || '',
    }, [isPrinting]);
  } else {
    editor = useEditor({
      extensions: editorExtensions,
      content,
      onUpdate: debouncedSaveDocument,
      editable: canEdit,
      onSelectionUpdate: ({editor}) => {
        if (!editor.state.selection.empty) {
          setSelectionCount(prev => prev + 1);
        }
      },
      onCreate: ({editor}) => {
        editorRef.current = editor;
      },
    }, [isPrinting]);
  }

  const addTableToPage = useCallback(() => {
    const defaultTableData = [
      ['', '', '', ''], // default merged header cell
      ['', '2024', '', '2023'], // default header cell
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
      ['', '', '', ''],
    ];

    const defaultTableDataJSON = JSON.stringify(defaultTableData);
    const defaultMergedCells = [{row: 0, col: 1, rowspan: 1, colspan: 3}];
    const defaultMergedCellsJSON = JSON.stringify(defaultMergedCells);

    const tableHTML = `<document-table table-data='${defaultTableDataJSON}' table-meta='{}' table-merged-cells='${defaultMergedCellsJSON}' table-size-multiplier='1'></document-table><p></p>`;

    editor
      .chain()
      .focus()
      .insertContent(tableHTML)
      .run();
  }, [editor]);

  useEffect(() => {
    if (flags.collaborativeDocuments && !isAutoVersioning && historyDataFetched) {
      editor?.commands.toggleVersioning();
    }
  }, [editor, isAutoVersioning, historyDataFetched]);

  useEffect(() => {
    editor?.commands.focus('start');
  }, [editor]);

  useEffect(() => {
    setTimeout(() => {
      editor?.value?.view.dispatch(editor.value?.state.tr.setMeta('splitPage', true));
    }, 1000);
  }, []);

  useEffect(() => {
    if (drawerStates.history && flags.collaborativeDocuments) {
      if (isPrinting && versionPreviewEditor && onPrintContentReady) {
        if (storedDiffContent) versionPreviewEditor.commands.showDiff(storedDiffContent);
        onPrintContentReady();
      }
    } else {
      if (isPrinting && editor && onPrintContentReady) {
        onPrintContentReady();
      }
    }
  }, [isPrinting, editor, versionPreviewEditor, onPrintContentReady]);

  useEffect(() => {
    if (!editor) return;

    const handlePointerDown = (event) => {
      const tableElement = event.target.closest('.handsontable');

      if (tableElement) {
        setIsTableActive(true);
      } else {
        setIsTableActive(false);
      }
    };

    document.addEventListener('mousedown', handlePointerDown);

    return () => {
      document.removeEventListener('mousedown', handlePointerDown);
    };
  }, [editor]);

  useEffect(() => {
    const handleNewVersion = (data) => {
      const payload = JSON.parse(data.payload)
      if (payload.event === 'version.created') {
        insertYDocUserMeta(provider, payload.version, name);
      };
    };

    provider.on('stateless', handleNewVersion);

    return () => {
      provider.off('stateless', handleNewVersion);
    };
  }, []);

  const memoizedCommentSelectionMenu = useCallback(() => {
    if (drawerStates.history) {
      return <CommentSelectionMenu editor={versionPreviewEditor} skipShow={true} />
    } else {
      return <CommentSelectionMenu editor={editor} />
    }
  }, [editor, versionPreviewEditor, selectionCount, drawerStates.history]);

  const handleVersionPreviewLoading = () => {
    if (!isLoadingVersionPreview) {
      setIsLoadingVersionPreview(true);

      setTimeout(() => {
        setIsLoadingVersionPreview(false);
      }, 2000);
    }
  };

  const contentWrapperStyle = {
    flexGrow: 1,
    display: 'flex',
    height: '100%',
    px: '1.8675rem',
    overflow: 'scroll',
    scrollPaddingBlockStart: '20%',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
    ...((isDrawerOpen) && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: COPILOT_DRAWER_WIDTH,
    }),
    /**
     * This is necessary to enable the selection of content. In the DOM, the stacking order is determined
     * by the order of appearance. Following this rule, elements appearing later in the markup will overlay
     * those that appear earlier. Since the Drawer comes after the Main content, this adjustment ensures
     * proper interaction with the underlying content.
     */
    position: 'relative',
  };

  return (
    <>
      <DocumentToolbar
        editor={editor}
        readOnly={!canEdit}
        addTable={addTableToPage}
        enterPrintMode={enterPrintMode}
        isSaving={isSaving}
        isTableActive={isTableActive}
      />

      <Box mt={'8rem'} sx={contentWrapperStyle}>
        <CommonPageWrapper>
          <Stack direction='column' alignItems='center' flex={1} sx={{position: 'relative'}}>
            <Box sx={{display: 'grid', flexGrow: 1, borderRadius: '8px', placeItems: 'center'}}>
              {drawerStates.history
                ? (
                  <Box sx={{opacity: isLoadingVersionPreview ? 0.5 : 1}}>
                    <EditorContent ref={contentToPrintRef} editor={versionPreviewEditor} />
                  </Box>
                  )
                : (
                  <EditorContent ref={contentToPrintRef} editor={editor} />
                  )
              }
            </Box>

            {isLoadingVersionPreview && (
              <Loading
                loading={true}
                loadingProps={{size: '46'}}
                containerProps={{
                  position: 'fixed',
                  zIndex: 1000,
                  top: 0,
                }}
              />
            )}
          </Stack>

          {memoizedCommentSelectionMenu()}
        </CommonPageWrapper>

        {editor && editor.storage?.commentHighlight?.showComposer && (
          <CommentComposer
            editor={editor}
            readOnlySave={flags.collaborativeDocuments ? () => {} : triggerSaveForReadOnly}
            containerRef={contentToPrintRef}
            isDrawerOpen={isDrawerOpen}
            openComments={() => openDrawer('comments')}
          />
        )}
      </Box>

      <CommentDrawer
        editor={editor}
        readOnlySave={flags.collaborativeDocuments ? () => {} : triggerSaveForReadOnly}
        open={drawerStates.comments}
        onClose={() => closeDrawer('comments')}
        readOnly={!canEdit}
      />

      {flags.collaborativeDocuments &&
        <HistoryDrawer
          parentEditor={editor}
          versionPreviewEditor={versionPreviewEditor}
          ydoc={doc}
          provider={provider}
          open={drawerStates.history}
          onClose={() => closeDrawer('history')}
          versions={versions}
          setVersionLoading={handleVersionPreviewLoading}
          setStoredVersionContent={setStoredVersionContent}
          setStoredDiffContent={setStoredDiffContent}
        />
      }
    </>
  );
});

DocumentEditor.displayName = 'DocumentEditor';

DocumentEditor.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  contentToPrintRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)}),
  ]),
  doc: PropTypes.object.isRequired,
  editorRef: PropTypes.shape({
    current: PropTypes.any,
  }),
  enterPrintMode: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    collaborativeDocuments: PropTypes.bool.isRequired,
  }),
  isSaving: PropTypes.bool.isRequired,
  isPrinting: PropTypes.bool.isRequired,
  onPrintContentReady: PropTypes.func.isRequired,
  provider: PropTypes.object.isRequired,
  saveDocument: PropTypes.func.isRequired,
};

export default DocumentEditor;
