import React, {useState} from 'react';

import {
  Box,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';

import MoreIcon from '../../../assets/more-horizontal.svg';
import {BLACK_100} from '../../../theme';
import PropTypes from 'prop-types';
import {formatDate} from './utils';
import {uniq} from 'lodash';

const VersionMenu = ({
  anchorElVersion,
  handleCloseVersionMenu,
  onClick,
  showRestore,
}) => {
  return (
    <Menu
      disableAutoFocusItem={true}
      PaperProps={{
        style: {
          width: '200px',
          borderRadius: '4px',
          boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
        },
      }}
      anchorEl={anchorElVersion}
      keepMounted
      open={Boolean(anchorElVersion)}
      onClose={handleCloseVersionMenu}
    >
      <MenuItem sx={{height: 40}} key="rename-version" onClick={() => onClick('rename')}>
        <Typography color={BLACK_100} fontWeight={400}>Name this version</Typography>
      </MenuItem>

      {showRestore &&
        <MenuItem sx={{height: 40}} key="restore-version" onClick={() => onClick('restore')}>
          <Typography color={BLACK_100} fontWeight={400}>Restore this version</Typography>
        </MenuItem>
      }
    </Menu>
  );
};

VersionMenu.propTypes = {
  anchorElVersion: PropTypes.object,
  handleCloseVersionMenu: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  showRestore: PropTypes.bool.isRequired,
};

const VersionItem = ({
  version,
  isActive,
  isComparing,
  isCurrent,
  selectedVersionsForCompare,
  addVersionToCompare,
  removeVersionFromCompare,
  onClick,
  openVersionModal,
  users,
}) => {
  const [anchorElVersion, setAnchorElVersion] = useState(null);

  const isRestoredVersion = version.name?.includes('Restored') || version.name?.includes('Unsaved');
  const isSelectedForCompare = selectedVersionsForCompare.includes(version.version);

  const getVersionName = version => {
    if (version.name && !isRestoredVersion) {
      return version.name;
    }

    if (version.version === 0) {
      return 'Initial version';
    }

    if (isCurrent) {
      return 'Current version';
    }

    return formatDate(version.date);
  };

  const handleClick = () => {
    if (isComparing) {
      if (selectedVersionsForCompare.length < 2 || isSelectedForCompare) {
        handleCheck();
      }
      return;
    }

    if (!isActive) {
      onClick();
    }
  };

  const handleOpenVersionMenu = (event) => {
    setAnchorElVersion(event.currentTarget);
  };

  const handleCloseVersionMenu = () => {
    setAnchorElVersion(null);
  };

  const handleMenuItemClick = (type) => {
    openVersionModal(type);
    handleCloseVersionMenu();
  };

  const handleCheck = () => {
    if (isSelectedForCompare) {
      removeVersionFromCompare(version.version);
    } else {
      addVersionToCompare(version.version);
    }
  };

  const prevVersionStyles = {
    container: {
      width: '100%',
      display: 'flex',
      padding: '8px 8px 8px 16px',
      borderLeft: '1px solid #DADFDC',
      position: 'relative',
    },
    dot: {
      position: 'absolute',
      left: '-6.5px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '12px',
      height: '12px',
      backgroundColor: '#DADFDC',
      borderRadius: '50%',
    },
    button: {
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '8px 16px 8px 12px',
      borderRadius: '4px',
      backgroundColor: isComparing
        ? (isSelectedForCompare ? '#E2F7E6' : '#FFF')
        : (isActive ? '#E2F7E6' : '#FFF'),
      '&:hover': {
        backgroundColor: isComparing
          ? (isSelectedForCompare ? '#E2F7E6' : '#F1F4F2')
          : (isActive ? '#E2F7E6' : '#F1F4F2'),
      },
    },
    versionName: {
      color: BLACK_100,
      fontWeight: 600,
      fontSize: '14px',
    },
    subtext: {
      color: '#727E7C',
      fontSize: '12px',
      fontWeight: 500,
      mt: '3px',
    },
  };

  const currVersionStyles = {
    button: {
      width: '100%',
      padding: '8px 8px 8px 12px',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '4px',
      backgroundColor: isComparing
        ? (isSelectedForCompare ? '#E2F7E6' : '#FFF')
        : (isActive ? '#E2F7E6' : '#FFF'),
      '&:hover': {
        backgroundColor: isComparing
          ? (isSelectedForCompare ? '#E2F7E6' : '#F1F4F2')
          : (isActive ? '#E2F7E6' : '#F1F4F2'),
      },
    },
    title: {
      color: isActive ? '#168877' : BLACK_100,
      fontWeight: 600,
      fontSize: '14px',
    },
  };

  const checkboxStyles = {
    p: 0,
    '&.Mui-checked': {
      color: '#168877',
    },
  };

  if (isCurrent) {
    return (
      <Box>
        <Box sx={currVersionStyles.button} onClick={handleClick}>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Typography sx={currVersionStyles.title}>{getVersionName(version)}</Typography>
            <Typography sx={prevVersionStyles.subtext}>{uniq(users).join(', ')}</Typography>
            {isRestoredVersion && <Typography sx={prevVersionStyles.subtext}>{version.name}</Typography>}
          </Box>

          {isActive && !isComparing &&
            <IconButton sx={{p: 0}} onClick={handleOpenVersionMenu}>
              <img src={MoreIcon} alt='More options' />
            </IconButton>
          }

          {isComparing &&
            <Checkbox
              disabled={selectedVersionsForCompare.length >= 2 && !isSelectedForCompare}
              checked={isSelectedForCompare}
              onChange={handleCheck}
              sx={checkboxStyles}
            />
          }
        </Box>

        <VersionMenu
          anchorElVersion={anchorElVersion}
          handleCloseVersionMenu={handleCloseVersionMenu}
          onClick={handleMenuItemClick}
          showRestore={false}
        />
      </Box>
    );
  } else {
    const showDot = version.name && !isRestoredVersion;

    return (
      <Box sx={prevVersionStyles.container}>
        {showDot && <Box sx={prevVersionStyles.dot} />}
        <Box sx={prevVersionStyles.button} onClick={handleClick}>
          <Box display='flex' flexDirection='column' alignItems='flex-start'>
            <Typography sx={prevVersionStyles.versionName}>{getVersionName(version)}</Typography>
            <Typography sx={prevVersionStyles.subtext}>{uniq(users).join(', ')}</Typography>
            {isRestoredVersion && <Typography sx={prevVersionStyles.subtext}>{version.name}</Typography>}
          </Box>

          {isActive && !isComparing &&
            <IconButton sx={{p: 0}} onClick={handleOpenVersionMenu}>
              <img src={MoreIcon} alt='More options' />
            </IconButton>
          }

          {isComparing &&
            <Checkbox
              disabled={selectedVersionsForCompare.length >= 2 && !isSelectedForCompare}
              checked={isSelectedForCompare}
              onChange={handleCheck}
              sx={checkboxStyles}
            />
          }
        </Box>

        <VersionMenu
          anchorElVersion={anchorElVersion}
          handleCloseVersionMenu={handleCloseVersionMenu}
          onClick={handleMenuItemClick}
          showRestore={true}
        />
      </Box>
    )
  }
}

VersionItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isComparing: PropTypes.bool.isRequired,
  isCurrent: PropTypes.bool,
  selectedVersionsForCompare: PropTypes.array.isRequired,
  addVersionToCompare: PropTypes.func.isRequired,
  removeVersionFromCompare: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  openVersionModal: PropTypes.func.isRequired,
  version: PropTypes.object.isRequired,
  users: PropTypes.array,
};

export default VersionItem;
