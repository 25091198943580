import React from 'react';
import PropTypes from 'prop-types';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';

// Context
import {useDrawer} from '../../context/DrawerContext';

// MUI
import {
  AppBar,
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Toolbar,
  Select,
  Tooltip,
  Typography,
  Stack,
} from '@mui/material';

// Icons
import BoldIcon from '../../assets/bold.svg';
import ItalicIcon from '../../assets/italic.svg';
import UnderlineIcon from '../../assets/underline.svg';
import NewTableIcon from '../../assets/columns.svg';
import BulletListIcon from '../../assets/bullet-list.svg';
import NumberedListIcon from '../../assets/numbered-list.svg';
import AlignLeftIcon from '../../assets/align-left.svg';
import AlignRightIcon from '../../assets/align-right.svg';
import AlignCenterIcon from '../../assets/align-center.svg';
import AiStarIcon from '../../assets/ai-star.svg';
import CommentIcon from '../../assets/comment.svg';
import HistoryIcon from '../../assets/clock.svg';
import DownloadIcon from '../../assets/download.svg';
import RefreshLinksIcon from '../../assets/refresh-links.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {APP_HEADER_HEIGHT, GREY_70, WHITE_100, BLACK_100} from '../../App';

const TextStyleDropdown = ({editor}) => {
  const toggleHeadingWithAttributes = (newLevel) => {
    const currentAttributes = editor.getAttributes('heading');
    const alignment = currentAttributes.textAlign || editor.getAttributes('paragraph').textAlign || 'left';
    editor.chain().focus().toggleHeading({level: Number(newLevel), textAlign: alignment}).run();
  };

  const setParagraphWithAttributes = () => {
    const otherAttributes = editor.getAttributes('heading');
    if (Object.keys(otherAttributes).length === 0) {
      editor.chain().focus().setParagraph().run();
    } else {
      const alignment = otherAttributes.textAlign || 'left';
      editor.chain().focus().setParagraph().setTextAlign(alignment).run();
    }
  };

  const handleChange = (event) => {
    const style = event.target.value;
    switch (style) {
      case 'paragraph':
        setParagraphWithAttributes();
        break;
      case 'heading1':
        toggleHeadingWithAttributes(1);
        break;
      case 'heading2':
        toggleHeadingWithAttributes(2);
        break;
      case 'heading3':
        toggleHeadingWithAttributes(3);
        break;
      default:
        console.log('Unknown text style:', style);
    }
  };

  const getDisplayName = (value) => {
    switch (value) {
      case 'paragraph':
        return 'Normal';
      case 'heading1':
        return 'Huge';
      case 'heading2':
        return 'Large';
      case 'heading3':
        return 'Small';
      default:
        return 'Normal';
    }
  };

  return (
    <FormControl>
      <Select
        onChange={handleChange}
        value={
          editor.isActive('paragraph')
            ? 'paragraph'
            : editor.isActive('heading', {level: 1})
              ? 'heading1'
              : editor.isActive('heading', {level: 2})
                ? 'heading2'
                : editor.isActive('heading', {level: 3})
                  ? 'heading3'
                  : 'paragraph'
        }
        IconComponent={ExpandMoreIcon}
        renderValue={(value) => (
          <Typography sx={{fontSize: 14}}>
            {getDisplayName(value)}
          </Typography>
        )}
        MenuProps={{
          PaperProps: {
            sx: {
              width: 180,
              mt: 1,
              borderRadius: '4px',
              boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
              '& .MuiMenuItem-root': {
                mx: 1,
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: '#EFEFEF',
                },
                '.Mui-selected': {
                  backgroundColor: '#D4EDD0 !important',
                },
              },
              '& .MuiMenu-list': {
                py: 1,
              },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: '#D4EDD0 !important',
              },
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        sx={{
          borderRadius: '4px',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': {border: '0 !important'},
          '&:hover': {
            backgroundColor: '#D4EDD0',
          },
          '&.Mui-focused': {
            backgroundColor: '#D4EDD0',
          },
          '& .MuiSvgIcon-root': {
            fontSize: '16px',
            color: '#000',
          },
        }}>
        <MenuItem value={'heading3'}>
          <Typography sx={{fontSize: 10}}>Small</Typography>
        </MenuItem>
        <MenuItem value={'paragraph'}>
          <Typography sx={{fontSize: 14}}>Normal</Typography>
        </MenuItem>
        <MenuItem value={'heading2'}>
          <Typography sx={{fontSize: 18}}>Large</Typography>
        </MenuItem>
        <MenuItem value={'heading1'}>
          <Typography sx={{fontSize: 32}}>Huge</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

TextStyleDropdown.propTypes = {
  editor: PropTypes.object.isRequired,
};

const TextStyleButton = ({editor, name, icon, tooltipText, isDisabled, onClick}) => {
  return (
    <Tooltip
      enterDelay={1000}
      title={<Typography sx={{p: '5px'}} fontSize={14}>{tooltipText}</Typography>}
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            borderRadius: '4px',
            backgroundColor: '#27353A',
            py: 0,
            px: 0.2,
          },
        },
      }}
    >
      <span>
        <IconButton
          sx={{
            mx: 0.2,
            borderRadius: '8px',
            '&:hover': {backgroundColor: '#D4EDD0'},
            backgroundColor: editor.isActive(name) && '#D4EDD0',
          }}
          aria-label={name}
          onClick={onClick}
          disabled={isDisabled}
        >
          <img width={20} height={20} src={icon} alt={name} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

TextStyleButton.propTypes = {
  editor: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const TextAlignmentDropdown = ({editor}) => {
  const handleChange = (event) => {
    const style = event.target.value;
    switch (style) {
      case 'align-left':
        editor.chain().focus().setTextAlign('left').run();
        break;
      case 'align-center':
        editor.chain().focus().setTextAlign('center').run();
        break;
      case 'align-right':
        editor.chain().focus().setTextAlign('right').run();
        break;
      default:
        console.log('Unknown text alignment:', style);
    }
  };

  return (
    <FormControl>
      <Select
        onChange={handleChange}
        value={
          editor.isActive({textAlign: 'left'})
            ? 'align-left'
            : editor.isActive({textAlign: 'center'})
              ? 'align-center'
              : editor.isActive({textAlign: 'right'})
                ? 'align-right'
                : 'align-left'
        }
        IconComponent={() => null}
        inputProps={{sx: {padding: '0 !important'}}}
        MenuProps={{
          PaperProps: {
            sx: {
              width: 140,
              mt: 1,
              borderRadius: '8px',
              boxShadow: '0px 2px 12px 0px rgba(15, 30, 36, 0.10)',
              '& .MuiMenuItem-root': {
                display: 'inline-block !important',
                mx: 0.3,
                borderRadius: '4px',
                padding: 0,
                '&:hover': {
                  backgroundColor: '#EFEFEF',
                },
                '.Mui-selected': {
                  backgroundColor: '#D4EDD0 !important',
                },
              },
              '& .MuiMenu-list': {
                px: 0.3,
                py: 0.5,
              },
              '& .MuiMenuItem-root.Mui-selected': {
                backgroundColor: '#D4EDD0 !important',
              },
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        sx={{
          borderRadius: '8px',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': {border: '0 !important'},
          '&:hover': {
            backgroundColor: '#D4EDD0',
          },
          '&.Mui-focused': {
            backgroundColor: '#D4EDD0',
          },
        }}
      >
        <MenuItem value='align-left'>
          <IconButton
            sx={{
              mx: 0.2,
              borderRadius: '8px',
              '&:hover': {backgroundColor: 'transparent'},
            }}
          >
            <img width={20} height={20} src={AlignLeftIcon} alt='align-left' />
          </IconButton>
        </MenuItem>
        <MenuItem value='align-center'>
          <IconButton
            sx={{
              mx: 0.2,
              borderRadius: '8px',
              '&:hover': {backgroundColor: 'transparent'},
            }}
          >
            <img width={20} height={20} src={AlignCenterIcon} alt='align-center' />
          </IconButton>
        </MenuItem>
        <MenuItem value='align-right'>
          <IconButton
            sx={{
              mx: 0.2,
              borderRadius: '8px',
              '&:hover': {backgroundColor: 'transparent'},
            }}
          >
            <img width={20} height={20} src={AlignRightIcon} alt='align-right' />
          </IconButton>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

TextAlignmentDropdown.propTypes = {
  editor: PropTypes.object.isRequired,
};

const ToolbarButton = ({icon, onClick, tooltipText, backgroundColor}) => {
  return (
    <Tooltip
      enterDelay={500}
      title={<Typography sx={{p: '5px'}} fontSize={14}>{tooltipText}</Typography>}
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            borderRadius: '4px',
            backgroundColor: '#27353A',
            py: 0,
            px: 0.2,
          },
        },
      }}
    >
      <IconButton
        alt={tooltipText}
        variant='outlined'
        onClick={onClick}
        sx={{
          mx: 1,
          backgroundColor: backgroundColor || 'initial',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: '#D4EDD0',
          },
        }}
      >
        <img src={icon} width={20} height={20} alt={tooltipText} />
      </IconButton>
    </Tooltip>
  );
};

ToolbarButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

const DocumentToolbar = ({
  editor,
  readOnly,
  addTable,
  flags,
  enterPrintMode,
  isSaving,
  isTableActive,
}) => {
  if (!editor) {
    return null
  }

  const {drawerStates, toggleDrawer} = useDrawer();

  const styles = {
    toolbar: {
      height: '100%',
      minHeight: APP_HEADER_HEIGHT + '!important',
      borderBottom: '1px solid #DDE0DE',
      background: WHITE_100,
      alignItems: 'center',
    },
    disabledToolbar: {
      opacity: 0.3,
      pointerEvents: 'none',
    },
  };

  const textStyleButtons = [
    {
      name: 'bold',
      icon: BoldIcon,
      isDisabled: !editor.can().chain().focus().toggleBold().run(),
      onClick: () => editor.chain().focus().toggleBold().run(),
      tooltipText: 'Bold',
    },
    {
      name: 'italic',
      icon: ItalicIcon,
      isDisabled: !editor.can().chain().focus().toggleItalic().run(),
      onClick: () => editor.chain().focus().toggleItalic().run(),
      tooltipText: 'Italic',
    },
    {
      name: 'underline',
      icon: UnderlineIcon,
      isDisabled: !editor.can().chain().focus().toggleUnderline().run(),
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      tooltipText: 'Underline',
    },
    {
      name: 'bulletList',
      icon: BulletListIcon,
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      tooltipText: 'Bulleted list',
    },
    {
      name: 'orderedList',
      icon: NumberedListIcon,
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      tooltipText: 'Numbered list',
    },
  ];

  const isToolbarDisabled = isTableActive || drawerStates.history;

  return (
    <AppBar position='fixed' sx={{boxShadow: 'none', backgroundColor: GREY_70, marginTop: APP_HEADER_HEIGHT, height: 56, zIndex: 999}}>
      <Toolbar sx={styles.toolbar}>
        <Stack direction={'row'} flexGrow={1} alignItems={'center'} sx={{...(isToolbarDisabled && styles.disabledToolbar)}}>
          {readOnly
            ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '5px 8px',
                  gap: '10px',
                  backgroundColor: '#DDE0DE',
                  borderRadius: '8px',
                }}>
                <Typography color={BLACK_100} fontSize='12px'>Review only</Typography>
              </Box>
              )
            : (
              <>
                <TextStyleDropdown editor={editor} />

                {textStyleButtons.filter(b => ['bold', 'italic', 'underline'].includes(b.name)).map((button) => (
                  <TextStyleButton
                    key={button.name}
                    editor={editor}
                    name={button.name}
                    icon={button.icon}
                    tooltipText={button.tooltipText}
                    isDisabled={button.isDisabled}
                    onClick={button.onClick}
                  />
                ))}

                <Box mx={0.2}>
                  <TextAlignmentDropdown editor={editor} />
                </Box>

                {textStyleButtons.filter(b => ['bulletList', 'orderedList'].includes(b.name)).map((button) => (
                  <TextStyleButton
                    key={button.name}
                    editor={editor}
                    name={button.name}
                    icon={button.icon}
                    tooltipText={button.tooltipText}
                    isDisabled={button.isDisabled}
                    onClick={button.onClick}
                  />
                ))}

                <Box sx={{mx: 1}}>
                  <Divider orientation='vertical' variant='middle' flexItem sx={{borderRightWidth: '2px', backgroundColor: '#D3D6D4', height: 25}} />
                </Box>

                <Tooltip
                  enterDelay={500}
                  title={<Typography sx={{p: '5px'}} fontSize={14}>Insert table</Typography>}
                  placement="bottom"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        borderRadius: '4px',
                        backgroundColor: '#27353A',
                        py: 0,
                        px: 0.2,
                      },
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      borderRadius: '8px',
                      '&:hover': {backgroundColor: '#D4EDD0'},
                    }}
                    aria-label='new-table'
                    onClick={() => addTable()}
                  >
                    <img width={20} height={20} src={NewTableIcon} alt='new table' />
                  </IconButton>
                </Tooltip>
              </>
              )
            }
        </Stack>

        {!readOnly &&
          <Box mx={1}>
            <Typography variant='body1' sx={{color: '#0F1E24', opacity: 0.6, fontSize: 14}}>
              {isSaving ? 'Saving...' : 'Saved'}
            </Typography>
          </Box>
        }

        <ToolbarButton
          icon={RefreshLinksIcon}
          onClick={() => window.location.reload()}
          tooltipText='Refresh links'
        />

        {flags.copilot && (
          <ToolbarButton
            icon={AiStarIcon}
            onClick={() => toggleDrawer('copilot')}
            tooltipText={drawerStates.copilot ? 'Close Copilot AI' : 'Open Copilot AI'}
            backgroundColor={drawerStates.copilot ? '#D4EDD0' : null}
          />
        )}

        <ToolbarButton
          icon={CommentIcon}
          onClick={() => toggleDrawer('comments')}
          tooltipText={drawerStates.comments ? 'Close comments' : 'Open comments'}
          backgroundColor={drawerStates.comments ? '#D4EDD0' : null}
        />

        {flags.collaborativeDocuments && (
          <ToolbarButton
            icon={HistoryIcon}
            onClick={() => toggleDrawer('history')}
            tooltipText={drawerStates.history ? 'Close history' : 'Open history'}
            backgroundColor={drawerStates.history ? '#D4EDD0' : null}
          />
        )}

        <ToolbarButton
          icon={DownloadIcon}
          onClick={enterPrintMode}
          tooltipText='Download report'
        />
      </Toolbar>
    </AppBar>
  );
}

DocumentToolbar.propTypes = {
  editor: PropTypes.object,
  readOnly: PropTypes.bool.isRequired,
  addTable: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    copilot: PropTypes.bool,
    collaborativeDocuments: PropTypes.bool,
  }).isRequired,
  enterPrintMode: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isTableActive: PropTypes.bool.isRequired,
};

export default withLDConsumer()(DocumentToolbar);
