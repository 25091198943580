import React, {useState, useEffect, useCallback, useMemo, useRef} from 'react';

import {watchPreviewContent} from '@tiptap-pro/extension-collaboration-history'

// Custom components
import VersionItem from './VersionItem';
import RenameVersionModal from './RenameVersionModal';
import RestoreVersionModal from './RestoreVersionModal';

// MUI
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ReactComponent as TargetIcon} from '../../../assets/target.svg';
import FilterIcon from '../../../assets/bar-chart.svg';
import CompareIcon from '../../../assets/compare.svg';
import CheckIcon from '@mui/icons-material/Check';

import {BLACK_100, WHITE_100, HISTORY_DRAWER_WIDTH} from '../../../theme';
import PropTypes from 'prop-types';
import {formatDate} from './utils';
import {debounce} from 'lodash'

const HEADER_HEIGHT = '2rem';

const styles = {
  header: {
    title: {
      color: BLACK_100,
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
  },
};

const FilterMenu = ({
  anchorElFilter,
  handleCloseFilterMenu,
  onClick,
  showNamedOnly,
}) => {
  return (
    <Menu
      disableAutoFocusItem={true}
      PaperProps={{
        style: {
          width: '200px',
          borderRadius: '4px',
          boxShadow: '0px 2px 12px 2px rgba(15, 30, 36, 0.12)',
        },
      }}
      anchorEl={anchorElFilter}
      keepMounted
      open={Boolean(anchorElFilter)}
      onClose={handleCloseFilterMenu}
    >
      <MenuItem sx={{height: 40}} key="rename-version" onClick={() => onClick('all')}>
        <CheckIcon sx={{visibility: !showNamedOnly ? 'visible' : 'hidden', color: '#0F1E24', width: 16, height: 16}} />
        <Typography color='#052E28' fontWeight={400} ml={1}>All versions</Typography>
      </MenuItem>

      <MenuItem sx={{height: 40}} key="restore-version" onClick={() => onClick('named')}>
        <CheckIcon sx={{visibility: showNamedOnly ? 'visible' : 'hidden', color: '#0F1E24', width: 16, height: 16}} />
        <Typography color='#052E28' fontWeight={400} ml={1} >Only named versions</Typography>
      </MenuItem>
    </Menu>
  );
};

FilterMenu.propTypes = {
  anchorElFilter: PropTypes.object,
  handleCloseFilterMenu: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  showNamedOnly: PropTypes.bool.isRequired,
};

const HistoryDrawerHeader = ({
  handleFilter,
  showNamedOnly,
  toggleCompare,
  isComparing,
  onClose,
}) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const handleOpenFilterMenu = (event) => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleCloseFilterMenu = () => {
    setAnchorElFilter(null);
  };

  const onFilter = (type) => {
    handleFilter(type);
    handleCloseFilterMenu();
  };

  return (
    <Stack direction={'row'} my={'0.25rem'} justifyContent={'space-between'} alignItems={'center'} height={HEADER_HEIGHT}>
      <Stack direction={'row'} spacing={'0.5rem'} alignItems={'center'}>
        <Typography sx={styles.header.title}>Version history</Typography>
      </Stack>

      <Stack direction={'row'} spacing={'0.5rem'} alignItems={'center'}>
        <IconButton
          variant='outlined'
          onClick={toggleCompare}
          sx={{
            backgroundColor: isComparing ? '#D4EDD0' : 'initial',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#D4EDD0',
            },
          }}
        >
          <img src={CompareIcon} width={20} height={20} />
        </IconButton>

        <IconButton
          variant='outlined'
          onClick={handleOpenFilterMenu}
          sx={{
            backgroundColor: showNamedOnly ? '#D4EDD0' : 'initial',
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#D4EDD0',
            },
          }}
        >
          <img src={FilterIcon} width={20} height={20} />
        </IconButton>

        <IconButton onClick={onClose}>
          <CloseIcon sx={{color: BLACK_100}} />
        </IconButton>
      </Stack>

      <FilterMenu
        anchorElFilter={anchorElFilter}
        handleCloseFilterMenu={handleCloseFilterMenu}
        onClick={onFilter}
        showNamedOnly={showNamedOnly}
      />
    </Stack>
  )
}

HistoryDrawerHeader.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  showNamedOnly: PropTypes.bool.isRequired,
  toggleCompare: PropTypes.func.isRequired,
  isComparing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const HistoryDrawerFooter = ({compareDisabled, onExit, onCompare}) => {
  const styles = {
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: '2.5rem',
      borderTop: '1px solid #DDE0DE',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#FFF',
      padding: '16px 24px 16px 24px',
    },
    exitButton: {
      mt: 3,
      padding: '11px 12px',
      height: '32px',
      borderRadius: '8px',
      color: BLACK_100,
      backgroundColor: '#D9D9D9',
      mr: 1,
      '&:hover': {
        backgroundColor: '#D9D9D9',
      },
    },
    compareButton: {
      mt: 3,
      flexGrow: 1,
      padding: '11px 12px',
      height: '32px',
      borderRadius: '8px',
      color: '#FFF',
      backgroundColor: '#22947F',
      '&:hover': {
        backgroundColor: '#22947F',
      },
      '&:disabled': {
        color: '#FFF',
        backgroundColor: '#22947F',
        opacity: 0.4,
      },
    },
    buttonLabel: {
      fontWeight: 500,
    },
  };

  return (
    <Box sx={styles.container}>
      <Button onClick={onExit} variant='contained' sx={styles.exitButton}>
        <Typography sx={styles.buttonLabel}>Exit compare</Typography>
      </Button>
      <Button onClick={onCompare} disabled={compareDisabled} variant='contained' sx={styles.compareButton}>
        <Typography sx={styles.buttonLabel}>Compare versions</Typography>
      </Button>
    </Box>
  );
};

HistoryDrawerFooter.propTypes = {
  compareDisabled: PropTypes.bool.isRequired,
  onCompare: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
};

const CurrentVersionIcon = ({isActive}) => {
  return (
    <TargetIcon
      width={16}
      height={16}
      style={{
        color: isActive ? '#FFF' : BLACK_100,
        fill: 'none',
        stroke: 'currentColor',
      }}
    />
  );
};

CurrentVersionIcon.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

const VersionListHeaderButton = ({versionCount, onToggle}) => {
  const styles = {
    button: {
      width: '100%',
      display: 'flex',
      paddingLeft: '12px',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundColor: '#FFF',
      '&:hover': {
        backgroundColor: '#F1F4F2',
      },
    },
    title: {
      color: '#727E7C',
      fontWeight: 600,
      fontSize: '14px',
    },
  }

  return (
    <Button sx={styles.button} onClick={onToggle}>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <Typography sx={styles.title}>
          {versionCount} saved versions
        </Typography>
      </Box>
    </Button>
  );
}

VersionListHeaderButton.propTypes = {
  versionCount: PropTypes.number.isRequired,
  onToggle: PropTypes.func.isRequired,
};

const HistoryDrawer = React.memo(({
  parentEditor,
  versionPreviewEditor,
  ydoc,
  provider,
  open,
  onClose,
  versions,
  setVersionLoading,
  setStoredVersionContent,
  setStoredDiffContent,
}) => {
  // Base state
  const [selectedVersionId, setSelectedVersionId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [renameVersionModalOpen, setRenameVersionModalOpen] = useState(false);
  const [restoreVersionModalOpen, setRestoreVersionModalOpen] = useState(false);

  // Filter state
  const [showNamedOnly, setShowNamedOnly] = useState(false);

  // Blacklining state
  const [isComparing, setIsComparing] = useState(false);
  const [selectedVersionsForCompare, setSelectedVersionsForCompare] = useState([]);

  const currentVersion = useMemo(() => versions.at(-1), [versions]);
  const previousVersions = useMemo(() => {
    const filteredVersions = showNamedOnly
      ? versions.slice(0, -1).filter(version => version.name && !version.name.includes('Restored') && !version.name.includes('Unsaved'))
      : versions.slice(0, -1);
    return filteredVersions.reverse();
  }, [versions, showNamedOnly]);

  const initialLoadRef = useRef(false);

  useEffect(() => {
    if (!open) {
      handleClose();
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      // Will not save a version on BE if no diff exists
      parentEditor.commands.saveVersion();
      initialLoadRef.current = false;
    }
  }, [open, parentEditor]);

  useEffect(() => {
    if (open && !initialLoadRef.current) {
      const debouncedHandleVersionChange = debounce(() => {
        handleVersionChange(currentVersion.version);
        initialLoadRef.current = true;
      }, 300);

      debouncedHandleVersionChange();

      return () => {
        debouncedHandleVersionChange.cancel();
      };
    }
  }, [open, currentVersion]);

  useEffect(() => {
    if (open) {
      const unbindContentWatcher = watchPreviewContent(provider, content => {
        if (versionPreviewEditor) {
          setStoredVersionContent(content);
          versionPreviewEditor.commands.setContent(content);
        }
      })

      return () => {
        unbindContentWatcher()
      }
    }
  }, [open, provider, versionPreviewEditor])

  const reset = () => {
    setSelectedVersionId(null);
    setShowNamedOnly(false);
    setIsComparing(false);
    setSelectedVersionsForCompare([]);
  };

  const handleClose = useCallback(() => {
    onClose();
    reset();
    if (versionPreviewEditor) {
      if (versionPreviewEditor.can().hideDiff()) {
        versionPreviewEditor.chain().hideDiff().run();
      }

      versionPreviewEditor.commands.clearContent();
      setStoredVersionContent('');
      setStoredDiffContent(null);
    }
  }, [onClose, versionPreviewEditor]);

  const handleVersionChange = useCallback(
    newVersion => {
      setSelectedVersionId(newVersion)
      setVersionLoading();

      provider.sendStateless(
        JSON.stringify({
          action: 'version.preview',
          version: newVersion,
        }),
      )
    },
    [provider],
  );

  const handleToggle = () => {
    setIsExpanded(prev => !prev);
  };

  const handleOpenVersionModal = useCallback(
    type => {
      if (type === 'rename') {
        setRenameVersionModalOpen(true);
      } else {
        setRestoreVersionModalOpen(true);
      }
    },
    [],
  );

  const versionData = useMemo(() => {
    if (!versions.length) {
      return null
    }

    return versions.find(v => v.version === selectedVersionId)
  }, [selectedVersionId, versions])

  const handleRestore = useCallback((versionData) => {
    const versionTitle = versionData.name || formatDate(versionData.date);
    parentEditor.commands.revertToVersion(
      versionData.version,
      `Restored from ${versionTitle}`,
      `Unsaved changes before restored from ${versionTitle}`,
    );
    handleClose();
  }, [parentEditor, versions])

  const handleFilter = (type) => {
    setShowNamedOnly(type === 'named');
  };

  const toggleCompare = () => {
    if (!isComparing) setSelectedVersionsForCompare([selectedVersionId]);
    setIsComparing(!isComparing);
  };

  const addVersionToCompare = (versionId) => {
    if (Number.isInteger(versionId) && !selectedVersionsForCompare.includes(versionId)) {
      setSelectedVersionsForCompare([...selectedVersionsForCompare, versionId]);
    }
  };

  const removeVersionFromCompare = (versionId) => {
    setSelectedVersionsForCompare(selectedVersionsForCompare.filter(id => id !== versionId));
  };

  const exitCompare = () => {
    versionPreviewEditor.chain().hideDiff().run();
    setIsComparing(false);
    setSelectedVersionsForCompare([]);
    setStoredDiffContent(null);
  };

  const onCompare = () => {
    setVersionLoading();
    const versionsToCompare = selectedVersionsForCompare.sort();
    versionPreviewEditor.chain().diffVersions({
      fromVersion: versionsToCompare[0],
      toVersion: versionsToCompare[1],
      onDiff: options => {
        if (options.error) {
          alert(options.error);
          return;
        }

        setStoredDiffContent(options.tr);
        versionPreviewEditor.commands.showDiff(options.tr);
      },
    })
  };

  const isCurrentVersionActive = (selectedVersionId === currentVersion?.version) || !versions?.length;
  const hasSelectionsForCompare = isComparing && selectedVersionsForCompare.length > 0;
  const compareDisabled = isComparing && selectedVersionsForCompare.length !== 2;

  return (
    <Drawer
      anchor={'right'}
      open={open}
      variant={'persistent'}
      onClose={handleClose}
      sx={{zIndex: 998}}
      PaperProps={{
        sx: {
          marginTop: '6.5rem',
          paddingBottom: '2.5rem',
          background: WHITE_100,
          borderLeft: '1px solid #DDE0DE',
          height: 'calc(100% - 6rem)',
        },
      }}
    >
      {open &&
        <Box sx={{position: 'relative', height: '100%'}}>
          <Box sx={{height: '100%', overflowY: isComparing && 'auto', paddingBottom: '3rem'}}>
            <Stack
              direction={'column'}
              sx={{
                width: HISTORY_DRAWER_WIDTH,
                paddingY: '1rem',
                paddingX: '1.25rem',
              }}>
              <HistoryDrawerHeader
                handleFilter={handleFilter}
                toggleCompare={toggleCompare}
                isComparing={isComparing}
                showNamedOnly={showNamedOnly}
                onClose={handleClose}
              />

              {isComparing && (
                <Box
                  sx={{
                    width: 'calc(100% + 2.5rem)',
                    height: '32px',
                    backgroundColor: '#DDE0DE',
                    marginX: '-1.25rem',
                    mt: 2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Typography sx={{ml: 2, color: BLACK_100, fontSize: '12px', fontWeight: 500}}>
                    Select two versions to run a comparison.
                    {hasSelectionsForCompare &&
                      <Link
                        onClick={() => setSelectedVersionsForCompare([])}
                        color="inherit"
                        ml={'3px'}
                        sx={{cursor: 'pointer'}}
                      >
                        Clear selection
                      </Link>
                    }
                  </Typography>
                </Box>
              )}

              <Timeline
                sx={{
                  mt: 1,
                  paddingBottom: 0,
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <TimelineItem sx={{marginLeft: '-13.5px !important'}}>
                  <TimelineSeparator>
                    <TimelineDot sx={{mb: 0, backgroundColor: isCurrentVersionActive ? '#168877' : '#DDE0DE', boxShadow: 0}}>
                      <CurrentVersionIcon isActive={isCurrentVersionActive} />
                    </TimelineDot>

                    <TimelineConnector sx={{width: '1px', backgroundColor: '#DADFDC'}} />
                  </TimelineSeparator>

                  <TimelineContent sx={{padding: '8px 0px 12px 6px'}}>
                    {currentVersion &&
                      <VersionItem
                        key='version_item_current'
                        version={currentVersion}
                        isCurrent={true}
                        onClick={() => handleVersionChange(currentVersion.version)}
                        isActive={isCurrentVersionActive}
                        isComparing={isComparing}
                        selectedVersionsForCompare={selectedVersionsForCompare}
                        addVersionToCompare={addVersionToCompare}
                        removeVersionFromCompare={removeVersionFromCompare}
                        openVersionModal={handleOpenVersionModal}
                        users={provider.configuration.document.getArray(`version_to_user_${currentVersion?.version}`).toArray()}
                      />
                    }
                  </TimelineContent>
                </TimelineItem>

                <TimelineItem sx={{marginLeft: '-13.5px !important', minHeight: '40px !important'}}>
                  <TimelineSeparator>
                    <TimelineDot sx={{my: 0, backgroundColor: '#DDE0DE', cursor: 'pointer', boxShadow: 0}} onClick={handleToggle}>
                      <ExpandMoreIcon
                        sx={{
                          color: BLACK_100,
                          fontSize: '16px',
                          transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
                          transition: 'transform 0.3s ease-in-out',
                        }}
                      />
                    </TimelineDot>

                    {isExpanded && previousVersions?.length > 0 && <TimelineConnector sx={{width: '1px', backgroundColor: '#DADFDC'}} />}
                  </TimelineSeparator>

                  <TimelineContent sx={{padding: '0px 0px 0px 6px'}}>
                    <VersionListHeaderButton
                      versionCount={previousVersions?.length}
                      isExpanded={isExpanded}
                      onToggle={handleToggle}
                    />
                  </TimelineContent>
                </TimelineItem>
              </Timeline>

              {isExpanded &&
                <Box ml={2} mt={-2}>
                  {previousVersions.map(v => (
                    <VersionItem
                      key={`version_item_${v.version}`}
                      version={v}
                      onClick={() => handleVersionChange(v.version)}
                      isCurrent={false}
                      isActive={selectedVersionId === v.version}
                      isComparing={isComparing}
                      selectedVersionsForCompare={selectedVersionsForCompare}
                      addVersionToCompare={addVersionToCompare}
                      removeVersionFromCompare={removeVersionFromCompare}
                      openVersionModal={handleOpenVersionModal}
                      users={provider.configuration.document.getArray(`version_to_user_${v.version}`).toArray()}
                    />
                  ))}
                </Box>
              }
            </Stack>
          </Box>

          {isComparing &&
            <HistoryDrawerFooter
              compareDisabled={compareDisabled}
              onCompare={onCompare}
              onExit={exitCompare}
            />
          }
        </Box>
      }

      {selectedVersionId !== null &&
        <RenameVersionModal
          ydoc={ydoc}
          editor={parentEditor}
          open={renameVersionModalOpen}
          onClose={() => setRenameVersionModalOpen(false)}
          versionId={selectedVersionId}
        />
      }

      {versionData &&
        <RestoreVersionModal
          editor={parentEditor}
          open={restoreVersionModalOpen}
          onClose={() => setRestoreVersionModalOpen(false)}
          version={versionData}
          onRestore={handleRestore}
        />
      }
    </Drawer>
  )
});

HistoryDrawer.propTypes = {
  parentEditor: PropTypes.object.isRequired,
  versionPreviewEditor: PropTypes.object.isRequired,
  ydoc: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  versions: PropTypes.array.isRequired,
  currentVersion: PropTypes.number,
  setVersionLoading: PropTypes.func.isRequired,
  setStoredVersionContent: PropTypes.func.isRequired,
  setStoredDiffContent: PropTypes.func.isRequired,
};

HistoryDrawer.displayName = 'HistoryDrawer';

export default HistoryDrawer;
