import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import React, {useEffect, useState} from 'react';
import {BLACK_100, WHITE_100, COPILOT_DRAWER_WIDTH} from '../../theme';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {TransitionGroup} from 'react-transition-group';
import AiStarIcon from '../../assets/ai-star.svg';
import ThumbsUpIcon from '../../assets/thumbs-up.svg';
import {useCreateOriginalJobMutation, useCreateCollabJobMutation, useLazyGetJobStatusQuery, useLazyGetErrorReportUrlQuery} from '../../data/copilotApi';
import Button from '@mui/material/Button';
import {setJobId, setCopilotItems, showReviewComplete} from '../../data/copilot';
import {setShowInitialCopilotScreen} from '../../data/session';
import TimeAgo from '../TimeAgo';
import axios from 'axios';
import _ from 'lodash';
import Card from './Card';
import Collapse from '@mui/material/Collapse';
import {useGetDocumentQuery} from '../../data/documentWorkspaceApi';
import {useParams} from 'react-router-dom';
import Loading from '../Loading';
import {trackEvent} from '../../util';
// import {sleep} from '../util';

const HEADER_HEIGHT = '2rem';

const styles = {
  header: {
    suggestionsLabel: {
      color: BLACK_100,
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
    },
  },
  body: {
    welcomeLabel: {
      color: BLACK_100,
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '1.75rem',
      textAlign: 'center',
    },
    introLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      opacity: 0.8,
      textAlign: 'center',
    },
    reviewButton: {
      borderRadius: '0.5rem',
      background: 'linear-gradient(0deg, #0F1E24 0%, #0F1E24 100%), #FFF;',
      gap: '0.625rem',
      height: '2.25rem',
    },
    reviewButtonLabel: {
      color: WHITE_100,
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: 500,
    },
    errorLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
    },
    openItemsLabel: {
      color: BLACK_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    asOfLabel: {
      color: BLACK_100,
      textAlign: 'right',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    footerLabel: {
      color: BLACK_100,
      fontSize: '0.6875rem',
      fontWeight: 400,
      lineHeight: '1.125rem',
      opacity: 0.5,
    },
    noItemsHeaderLabel: {
      color: BLACK_100,
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: 700,
      lineHeight: '1.5rem',
      opacity: 0.7,
    },
    noItemsTextLabel: {
      color: BLACK_100,
      textAlign: 'center',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
      opacity: 0.7,
    },
    finishedHeaderLabel: {
      color: BLACK_100,
      fontSize: '1.125rem',
      fontWeight: 700,
      lineHeight: '1.625rem',
    },
    finishedTextLabel: {
      color: BLACK_100,
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: '1.625rem',
    },
  },
}

const CopilotHeader = ({onClose}) => {
  return (
    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} height={HEADER_HEIGHT}>
      <Stack direction={'row'} my={'0.25rem'} spacing={'0.5rem'} alignItems={'center'}>
        <Typography sx={styles.header.suggestionsLabel}>Copilot</Typography>
      </Stack>
      <IconButton onClick={onClose}>
        <CloseIcon sx={{color: BLACK_100}} />
      </IconButton>
    </Stack>
  )
}

CopilotHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
};

const useCreateJob = (isCollaborativeDoc) => {
  const [createOriginalJob, createOriginalJobResult] = useCreateOriginalJobMutation();
  const [createCollabJob, createCollabJobResult] = useCreateCollabJobMutation();

  const createJob = isCollaborativeDoc ? createCollabJob : createOriginalJob;
  const jobResult = isCollaborativeDoc ? createCollabJobResult : createOriginalJobResult;

  return [createJob, jobResult];
};

const CopilotContent = ({open, flags}) => {
  const {documentId} = useParams();
  const dispatch = useDispatch();
  const {currentOrg, showInitialCopilotScreen} = useSelector(state => state.session);
  const copilot = useSelector(state => state.copilot);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [openItem, setOpenItem] = useState(0);
  const [createJob, createJobResult] = useCreateJob(flags.collaborativeDocuments);
  const [getDiscrepanciesJobStatus, getDiscrepanciesJobStatusResult] = useLazyGetJobStatusQuery();
  const [getDiscrepanciesSignedUrl, getDiscrepanciesSignedUrlResult] = useLazyGetErrorReportUrlQuery();
  const [getFooterJobStatus, getFooterJobStatusResult] = useLazyGetJobStatusQuery();
  const [getFooterSignedUrl, getFooterSignedUrlResult] = useLazyGetErrorReportUrlQuery();
  const {data: documentData, isFetching: documentIsFetching, isSuccess: documentIsSuccess} = useGetDocumentQuery({documentId}, {skip: _.isNil(documentId) || _.isNil(currentOrg), refetchOnMountOrArgChange: true});
  const [openItems, setOpenItems] = useState(copilot.openItems);
  const [startJobTimestamp, setStartJobTimestamp] = useState(null);

  let _createJobExec = null;
  let _getDiscrepanciesJobStatusExec = null;
  let _getDiscrepanciesSignedUrlExec = null;
  let _getFooterJobStatusExec = null;
  let _getFooterSignedUrlExec = null;

  useEffect(() => {
    setOpenItems(copilot.openItems);
  }, [copilot.openItems]);

  useEffect(() => {
    if (createJobResult.isSuccess) {
      console.log('Copilot job created successfully', {jobId: createJobResult.data.job_id, org: currentOrg.orgId});
      dispatch(setJobId({jobId: createJobResult.data.job_id}));
      _getDiscrepanciesJobStatusExec = getDiscrepanciesJobStatus({jobId: createJobResult.data.job_id, checkType: 'discrepancies'});
      _getFooterJobStatusExec = getFooterJobStatus({jobId: createJobResult.data.job_id, checkType: 'footing'});
    }
    if (createJobResult.isError) {
      console.error('Copilot job creation failed', {jobId: _.get(createJobResult, 'data.job_id', null), org: currentOrg.orgId});
      setHasError(true);
      setIsLoading(false);
      trackEvent('Copilot Job Failed', {jobId: copilot.jobId}, new Date().getTime() - startJobTimestamp);
      setStartJobTimestamp(null);
    }
    dispatch(setShowInitialCopilotScreen({showInitialCopilotScreen: false}));
  }, [createJobResult]);

  // Check the status of the Discrepancies job. When it's done, get the signed URL.
  useEffect(() => {
    let _hasDiscrepancyError = false;
    let _isDiscrepancyLoading = true;
    let _hasFootingError = false;
    let _isFootingLoading = true;
    if (getDiscrepanciesJobStatusResult.isFetching) {
      // no-op
    } else if (getDiscrepanciesJobStatusResult.isSuccess) {
      console.log('Copilot discrepancy job status retrieved successfully', {jobId: copilot.jobId, org: currentOrg.orgId, status: getDiscrepanciesJobStatusResult.data.status});
      if (getDiscrepanciesJobStatusResult.data.status === 'completed') {
        _getDiscrepanciesSignedUrlExec = getDiscrepanciesSignedUrl({jobId: copilot.jobId, checkType: 'discrepancies'});
        console.log('Copilot discrepancy job completed', {jobId: copilot.jobId, org: currentOrg.orgId, response: _getDiscrepanciesSignedUrlExec});
        _isDiscrepancyLoading = false;
      } else if (getDiscrepanciesJobStatusResult.data.status === 'failed') {
        _isDiscrepancyLoading = false;
        _hasDiscrepancyError = true;
      }
    } else if (getDiscrepanciesJobStatusResult.isError) {
      console.error('Copilot discrepancy job status retrieval failed', {jobId: copilot.jobId, org: currentOrg.orgId});
      _hasDiscrepancyError = true;
      _isDiscrepancyLoading = false;
    }
    if (getFooterJobStatusResult.isFetching) {
      // no-op
    } else if (getFooterJobStatusResult.isSuccess) {
      console.log('Copilot footing job status retrieved successfully', {jobId: copilot.jobId, org: currentOrg.orgId, status: getFooterJobStatusResult.data.status});
      if (getFooterJobStatusResult.data.status === 'completed') {
        _getFooterSignedUrlExec = getFooterSignedUrl({jobId: copilot.jobId, checkType: 'footing'});
        console.log('Copilot footings job completed', {jobId: copilot.jobId, org: currentOrg.orgId, response: _getFooterSignedUrlExec});
        _isFootingLoading = false;
      } else if (getFooterJobStatusResult.data.status === 'failed') {
        _isFootingLoading = false;
        _hasFootingError = true;
      }
    } else if (getFooterJobStatusResult.isError) {
      console.error('Copilot footing job status retrieval failed', {jobId: copilot.jobId, org: currentOrg.orgId});
      _hasFootingError = true;
      _isFootingLoading = false;
    }

    if (!_isDiscrepancyLoading && !_isFootingLoading) {
      setIsLoading(false);
      if (_hasDiscrepancyError || _hasFootingError) {
        createJobResult.reset();
        setHasError(true);
        trackEvent('Copilot Job Failed', {jobId: copilot.jobId}, new Date().getTime() - startJobTimestamp);
        setStartJobTimestamp(null);
      }
    }
  }, [getDiscrepanciesJobStatusResult, getFooterJobStatusResult]);

  /**
   * This useEffect hook is responsible for fetching the signed URLs for discrepancies and footing issues.
   * It checks if the fetching of signed URLs is successful and then makes an axios GET request to these URLs.
   * The data from these URLs is then dispatched to the Redux store using the setCopilotItems action.
   * If the drawer is not open, it triggers the showReviewComplete function.
   * If there is an error during this process, it sets the hasError and isLoading states accordingly.
   * After all these operations, it resets the createJobResult.
   * This hook runs every time the getDiscrepanciesSignedUrlResult or getFooterSignedUrlResult changes.
   */
  useEffect(() => {
    async function fetchData () {
      if (getDiscrepanciesSignedUrlResult.isFetching || getFooterSignedUrlResult.isFetching) {
        return;
      } else if (
        !getDiscrepanciesJobStatusResult.isFetching &&
        getDiscrepanciesSignedUrlResult.isSuccess &&
        !getFooterJobStatusResult.isFetching &&
        getFooterSignedUrlResult.isSuccess
      ) {
        try {
          console.log('Copilot jobs processed successfully, retrieving results', {jobId: copilot.jobId, org: currentOrg.orgId, discrepanciesSignedUrl: getDiscrepanciesSignedUrlResult.data, footingIssuesSignedUrl: getFooterSignedUrlResult.data});
          const _discrepanciesSignedUrl = await axios.get(getDiscrepanciesSignedUrlResult.data);
          const _footingIssuesSignedUrl = await axios.get(getFooterSignedUrlResult.data);
          console.log('Copilot jobs results loaded', {discrepancies: _discrepanciesSignedUrl.data, footingIssues: _footingIssuesSignedUrl.data});
          dispatch(setCopilotItems({discrepancies: _discrepanciesSignedUrl.data, footingIssues: _footingIssuesSignedUrl.data}));
          console.log('Copilot jobs results processed', {jobId: copilot.jobId, org: currentOrg.orgId});
          setIsLoading(false);
          if (!open) {
            dispatch(showReviewComplete());
          }
          trackEvent('Copilot Job Completed', {jobId: copilot.jobId}, new Date().getTime() - startJobTimestamp);
          setStartJobTimestamp(null);
        } catch (_error) {
          console.error('Error loading report', {error: _error});
          setHasError(true);
          setIsLoading(false);
          trackEvent('Copilot Job Failed', {jobId: copilot.jobId}, new Date().getTime() - startJobTimestamp);
          setStartJobTimestamp(null);
        }
      } else if (getDiscrepanciesSignedUrlResult.isError || getFooterSignedUrlResult.isError) {
        setHasError(true);
        setIsLoading(false);
        trackEvent('Copilot Job Failed', {jobId: copilot.jobId}, new Date().getTime() - startJobTimestamp);
        setStartJobTimestamp(null);
      }
      createJobResult.reset();
    }
    fetchData();
  }, [getDiscrepanciesSignedUrlResult, getFooterSignedUrlResult]);

  const startJob = () => {
    // Comment the following if you just want to test locally without running the entire pipeline.
    if (!isLoading && !getDiscrepanciesSignedUrl.isFetching && !getFooterSignedUrl.isFetching) {
      setIsLoading(true);
      setHasError(false);
      if (!documentIsFetching && documentIsSuccess) {
        if (flags.collaborativeDocuments) {
          _createJobExec = createJob({documentId: documentData.record.documentId}, {selectFromResult: () => ({})});
        } else {
          _createJobExec = createJob({sourceDocKey: documentData.record.s3Key}, {selectFromResult: () => ({})});
        }
      }
      setStartJobTimestamp(new Date().getTime());
      console.log('Copilot job creation request sent', {org: currentOrg.orgId});
    } else {
      setIsLoading(false);
      if (createJobResult.isFetching && !_.isNil(_createJobExec)) {
        _createJobExec.abort();
      }
      if (getDiscrepanciesJobStatusResult.isFetching && !_.isNil(_getDiscrepanciesSignedUrlExec)) {
        _getDiscrepanciesJobStatusExec.abort();
      }
      if (getFooterJobStatusResult.isFetching && !_.isNil(_getFooterJobStatusExec)) {
        _getFooterJobStatusExec.abort();
      }
      if (getFooterSignedUrl.isFetching && !_.isNil(_getFooterSignedUrlExec)) {
        _getFooterSignedUrlExec.abort();
      }
    }
    // Uncomment the following if you just want to test locally without running the entire pipeline (replace jobId with a valid job id in your environment)
    // setHasError(false);
    // setIsLoading(true);
    // sleep(5000).then(() => {
    //   getFooterSignedUrl({jobId: '4d260be4-ecbe-42e4-bbe1-754e3b1a2029', checkType: 'footing'});
    //   getDiscrepanciesSignedUrl({jobId: '4d260be4-ecbe-42e4-bbe1-754e3b1a2029', checkType: 'discrepancies'});
    // })
  }

  const renderReviewButton = () => {
    return (
      <Box pt={'1rem'} width={'100%'}>
        <Button fullWidth={true} sx={styles.body.reviewButton} onClick={startJob} disabled={documentIsFetching}>
          <Loading loading={isLoading} />
          {!isLoading && (
            <Stack direction={'row'} spacing={'0.25rem'} alignItems={'center'}>
              <img src={AiStarIcon} alt={''}/>
              <Typography style={styles.body.reviewButtonLabel}>Review my report</Typography>
            </Stack>
          )}
        </Button>
      </Box>
    )
  }

  const renderError = () => {
    if (hasError) {
      return (
        <Stack direction={'column'} spacing={'1rem'}>
          <Stack direction={'row'} gap={'0.75rem'} mx={'1rem'} padding={'0.75rem'} border={'1px solid #FFC738'} borderRadius={'0.5rem'} bgcolor={'#FFEEC3'}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} bgcolor={'#FFC738'} height={'1.25rem'} minWidth={'1.25rem'} borderRadius={'50%'}>
              <ErrorOutlineIcon sx={{height: '1rem', width: '1rem'}} />
            </Box>
            <Typography sx={styles.body.errorLabel}>An error occurred while loading results. Please try again. If the issue continues <a href="mailto:support@inscopehq.com">contact support</a>.</Typography>
          </Stack>
        </Stack>
      )
    }
  }

  const renderCards = () => {
    if (copilot.numItems === 0) {
      return (
        <Stack direction={'column'} height={`calc(100vh - 8rem - ${HEADER_HEIGHT} - 1rem - 2rem - 3.25rem - 1rem - 1rem - 1.25rem - 3.25rem - ${hasError ? '5rem - 1rem' : '0rem'})`} gap={'0.5rem'} justifyContent={'center'} alignItems={'center'}>
          <Typography style={styles.body.noItemsHeaderLabel}>You have no open items</Typography>
          <Typography style={styles.body.noItemsTextLabel}> To ensure accuracy, review your report to see if any new items are found. </Typography>
        </Stack>
      )
    }
    if (copilot.numItems >= 0 && copilot.numOpenItems === 0) {
      return (
        <Stack direction={'column'} height={`calc(100vh - 8rem - ${HEADER_HEIGHT} - 1rem - 2rem - 3.25rem - 1rem - 1rem - 1.25rem - 3.25rem - ${hasError ? '5rem - 1rem' : '0rem'})`} justifyContent={'center'} alignItems={'center'}>
          <Box marginBottom={'0.625rem'}>
            <img src={ThumbsUpIcon} alt={''} height={'32px'} width={'32px'}/>
          </Box>
          <Typography style={styles.body.finishedHeaderLabel}>Shall we say, hooray!</Typography>
          <Typography style={styles.body.finishedTextLabel}>Your report is looking sharp.</Typography>
        </Stack>
      )
    }
    return (
      <Stack direction={'column'} height={`calc(100vh - 8rem - ${HEADER_HEIGHT} - 1rem - 2rem - 3.25rem - 1rem - 1rem - 1.25rem - 3.25rem - ${hasError ? '5rem - 1rem' : '0rem'})`} overflow={'scroll'}>
        <TransitionGroup component={Box} >
          {_.map(_.values(openItems), (_item, _idx) => (
            <Collapse key={_idx} timeout={'auto'} unmountOnExit sx={{minHeight: 'fit-content', marginTop: _idx === 0 ? '0rem' : '1rem'}}>
              <Box>
                <Card setOpenItem={setOpenItem} openItem={openItem} idx={_idx} key={`${_idx}`} item={_item} isLoading={isLoading} />
              </Box>
            </Collapse>
          ))}
        </TransitionGroup>
      </Stack>
    )
  }

  const renderContent = () => {
    if (!_.isNil(copilot.asOf)) {
      return (
        <Stack direction={'column'} spacing={'1rem'} width={'100%'} flex={1}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography style={styles.body.openItemsLabel}>Open Items ({copilot.numOpenItems})</Typography>
            <Typography style={styles.body.asOfLabel}>
              {isLoading ? 'Review in progress...' : 'As of '}
              {!isLoading && <TimeAgo timestamp={copilot.asOf} />}
            </Typography>
          </Stack>
          {renderCards()}
        </Stack>
      )
    } else {
      return (
        <Stack direction={'column'} height={`calc(100vh - 8rem - ${HEADER_HEIGHT} - 1rem - 2rem - 3.25rem - 1rem - 1rem - 1.25rem - 1rem)`}/>
      )
    }
  }

  const renderFooter = () => {
    return (
      <Stack direction={'column'}>
        <Typography style={styles.body.footerLabel}>Copilot is in beta. Items found in review are suggestions and should be verified.</Typography>
        <Typography style={styles.body.footerLabel}>Job ID: {copilot.jobId}</Typography>
      </Stack>
    )
  }

  if (showInitialCopilotScreen) {
    return (
      <Stack direction={'column'} spacing={'1rem'} justifyContent={'center'} alignItems={'center'} height={`calc(100vh - 8rem - ${HEADER_HEIGHT} - 1rem)`}>
        <img width={38} height={38} src={AiStarIcon} alt='Copilot'/>
        <Typography sx={styles.body.welcomeLabel}>Hi, I&apos;m Copilot</Typography>
        <Box paddingLeft={'2.37rem'} paddingRight={'2.44rem'} textAlign={'center'}>
          <Typography sx={styles.body.introLabel}>A review assistant that can help you quickly identify calculation errors, misstatements, internal inconsistencies, missing disclosures and more...</Typography>
        </Box>
        {renderReviewButton()}
      </Stack>
    )
  }
  return (
    <Stack direction={'column'} spacing={'1rem'} alignItems={'center'} width={'100%'} flex={1}>
      {renderReviewButton()}
      {renderError()}
      <Box width={'100%'}>
        <Divider orientation={'horizontal'} sx={{width: '100%', opacity: 100, paddingLeft: '1rem'}} />
      </Box>
      {renderContent()}
      {renderFooter()}
    </Stack>
  )
}

CopilotContent.propTypes = {
  open: PropTypes.bool.isRequired,
  flags: PropTypes.shape({
    collaborativeDocuments: PropTypes.bool.isRequired,
  }),
}

const CopilotDrawer = ({flags, open, onClose}) => {
  const {showInitialCopilotScreen} = useSelector(state => state.session);

  return (
    <Drawer
      anchor={'right'}
      open={open}
      variant={'persistent'}
      onClose={onClose}
      sx={{zIndex: 998}}
      PaperProps={{sx: {paddingTop: '6.5rem', background: showInitialCopilotScreen ? 'linear-gradient(180deg, rgba(49, 198, 125, 0.00) 0%, rgba(49, 198, 125, 0.10) 100%), #FFF;' : WHITE_100, borderLeft: '1px solid #DDE0DE'}}}
    >
      <Stack direction={'column'} sx={{width: COPILOT_DRAWER_WIDTH, paddingY: '1rem'}} paddingX={'1.25rem'} paddingY={'1rem'}>
        <CopilotHeader onClose={onClose}/>
        <Box display={'flex'} flex={1}>
          <CopilotContent open={open} flags={flags} />
        </Box>
      </Stack>
    </Drawer>
  )
}

CopilotDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  flags: PropTypes.shape({
    collaborativeDocuments: PropTypes.bool.isRequired,
  }),
};

export default CopilotDrawer;
