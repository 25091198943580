import * as React from 'react';
import {Outlet, useParams} from 'react-router-dom';
import {Box, Stack} from '@mui/material';
import {useSelector} from 'react-redux';
import AppBarV2 from '../components/AppBarV2';
import _ from 'lodash';

import {DrawerProvider} from '../context/DrawerContext';

// LiveBlocks Collaboration
import {RoomProvider} from '../liveblocks.config';
import {ClientSideSuspense} from '@liveblocks/react';
import Loading from '../components/Loading';
import {GREY_70} from '../App';

const WorkspaceLayout = () => {
  const {documentId} = useParams();
  const {currentOrg} = useSelector((state) => state.session);

  return (
    <Stack direction={'column'} height={'100vh'} width={'100vw'}>
      <AppBarV2 />

      <DrawerProvider>
        <RoomProvider id={`${currentOrg.orgId}:${_.isNil(documentId) ? 'room' : documentId}`} initialPresence={{}}>
          <ClientSideSuspense fallback={
            <Loading loading={true}
                    message='Loading report'
                    loadingProps={{size: '46'}}
                    containerProps={{
                      position: 'fixed',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      bgcolor: GREY_70,
                      zIndex: 1000,
                      pt: '45px',
                    }}
            />
          }>
            {() => (
              <Box display='flex'>
                <Box sx={{flexGrow: 1, overflow: 'auto'}}>
                  <Outlet />
                </Box>
              </Box>
            )}
          </ClientSideSuspense>
        </RoomProvider>
      </DrawerProvider>
    </Stack>
  );
};

export default WorkspaceLayout;
