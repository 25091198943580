import {createApi} from '@reduxjs/toolkit/query/react';
import _ from 'lodash';
import axios from 'axios';
// import {useSelector} from 'react-redux';
import store from '../store';
import {sleep} from '../util';

const DEFAULT_WAIT_MS = 5000; // Retry every 5 seconds
const DEFAULT_RETRY_MAX = 60; // Retry for 5 minutes

const axiosBaseQuery =
  ({baseUrl} = {baseUrl: ''}) =>
    async ({url, method = 'GET', data, params, headers, ...opts}) => {
      const {session} = store.getState();
      const _headers = {
        'x-inscope-org': session.currentOrg.orgId,
        ...headers,
      }
      if (!_.isNil(session.accessToken)) {
        _headers.Authorization = `Bearer ${session.accessToken}`;
      }
      const _client = axios.create();
      try {
        _client.interceptors.request.use(async config => {
          // Axios interceptor to handle waiting on retries
          const _retry = _.get(opts, '_retry', false);
          if (!_retry) {
            return config;
          }
          const _iteration = _.get(config, '_iteration', 0);
          return {...config, _iteration};
        });
        _client.interceptors.response.use(async response => {
          const _retry = _.get(opts, '_retry', false);
          if (_retry && _.includes(['started', 'processing'], response.data.status)) {
            const _iteration = _.get(response, 'config._iteration', 0);
            if (_iteration === DEFAULT_RETRY_MAX) {
              console.error('Too many retries, aborting.');
              return Promise.reject(new Error('Too many retries, aborting.'));
            }
            await sleep(DEFAULT_WAIT_MS);
            console.log('Retrying...');
            return _client.request({...response.config, _iteration: _iteration + 1});
          }
          return response;
        }, error => {
          return Promise.reject(error);
        });
        const result = await _client({
          url: `${baseUrl}${url}`,
          method,
          data,
          params,
          headers: _headers,
        })
        return {data: result.data}
      } catch (axiosError) {
        const err = axiosError
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        }
      }
    }

export const copilotApiSlice = createApi({
  reducerPath: 'copilotApi',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_COPILOT_URL,
  }),
  endpoints: builder => ({
    createOriginalJob: builder.mutation({
      query: ({sourceDocKey}) => ({
        url: 'ingest_job',
        method: 'POST',
        data: {
          source_doc_key: sourceDocKey,
        },
      }),
    }),
    createCollabJob: builder.mutation({
      query: ({documentId}) => ({
        url: `ingest_job/${documentId}`,
        method: 'POST',
      }),
    }),
    dismissDiscrepancy: builder.mutation({
      query: ({data}) => ({
        url: 'suggestions/dismiss',
        method: 'POST',
        data,
      }),
    }),
    getErrorReportUrl: builder.query({
      query: ({jobId, checkType}) => ({
        url: `error_report/${jobId}/${checkType}`,
        method: 'GET',
      }),
    }),
    getJobStatus: builder.query({
      query: ({jobId, checkType}) => ({
        url: `ingest_job/${jobId}/${checkType}`,
        method: 'GET',
        _retry: true,
      }),
    }),
  }),
});

export const {
  endpoints,
  useCreateOriginalJobMutation,
  useCreateCollabJobMutation,
  useDismissDiscrepancyMutation,
  useLazyGetErrorReportUrlQuery,
  useLazyGetJobStatusQuery,
} = copilotApiSlice;
