export const BLACK_100 = '#0F1E24';
export const BLACK_90 = '#1E2C32';
export const WHITE_100 = '#FFF';
export const GREY_100 = '#A5B8AC';
export const GREY_90 = '#717773';
export const GREY_80 = '#DADFDB';
export const GREY_70 = '#F8FAF8';
// TODO consolidate these greens for consistency
export const GREEN_100 = '#2A413C';
export const GREEN_NAV = '#054A41';
export const GREEN_95 = '#3A5952';
export const GREEN_80 = '#D3F3C7';
export const GREEN_70 = '#ECF2EA';
export const GREEN_60 = '#77B65A';
export const GREEN_50 = '#C3F1D7';
export const COPILOT_DRAWER_WIDTH = '21.75rem';
export const COMMENT_DRAWER_WIDTH = '21.75rem';
export const HISTORY_DRAWER_WIDTH = '21.75rem';
