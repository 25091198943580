import React, {useState} from 'react';

import Loading from '../../Loading';

import {
  Box,
  Button,
  IconButton,
  Divider,
  Modal,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
import {BLACK_100, WHITE_100} from '../../../theme';
import PropTypes from 'prop-types';
import {insertYDocVersionMeta, touchEditor} from './utils';

const styles = {
  base: {
    borderRadius: '0.5rem',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.15)',
    backgroundColor: WHITE_100,
  },
  title: {
    fontSize: '1.125rem',
    fontWeight: '700',
    lineHeight: '120%',
    color: '#043B34',
  },
  divider: {
    borderBottomWidth: '0.0625rem',
    opacity: 1,
    color: '#DDe0DE',
  },
  label: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#043B34',
  },
  saveButton: {
    background: '#38CF7A',
    borderRadius: '0.5rem',
    paddingVertical: '0.625rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: '#38CF7A',
    },
  },
  saveButtonLabel: {
    fontSize: '0.875rem',
    lineHeight: '140%',
    fontWeight: '500',
  },
  cancelButton: {
    borderRadius: '0.5rem',
    paddingVertical: '0.6825rem',
    paddingHorizontal: '1rem',
    height: '2.5rem',
    '&:hover': {
      backgroundColor: WHITE_100,
    },
  },
  cancelButtonLabel: {
    color: '#043B34',
    fontSize: '0.875rem',
    fontWeight: '500',
    lineHeight: '130%',
  },
}

const RenameVersionModal = ({ydoc, editor, versionId, open, onClose}) => {
  const [versionName, setVersionName] = useState('');

  const renameVersion = () => {
    if (versionId === 'current') {
      touchEditor(editor);
      editor.commands.saveVersion(versionName);
    } else {
      insertYDocVersionMeta(ydoc, versionId, 'name', versionName);
    }

    setVersionName('');
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box position='absolute' sx={{left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
        <Box width='28rem' minWidth='28rem' maxWidth='28rem'>
          <Stack direction='column' sx={styles.base}>
            <Stack
              direction='row'
              paddingY='1rem'
              paddingLeft='1.5rem'
              paddingRight='1rem'
              flex={1}
              justifyContent='space-between'
              alignItems='center'
            >
              <Typography sx={styles.title}>Name this version</Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider orientation='horizontal' sx={styles.divider} />
            <Stack
              direction='column'
              paddingTop='1.25rem'
              paddingBottom='1.5rem'
              paddingX='1.5rem'
              spacing='1rem'
            >
              <Typography style={styles.label}>Version name</Typography>
              <TextField
                variant='outlined'
                value={versionName}
                onChange={(event) => {
                  setVersionName(event.target.value);
                }}
              />
            </Stack>
            <Divider orientation='horizontal' sx={styles.divider} />
            <Stack
              direction='row'
              paddingY='1rem'
              paddingLeft='1.5rem'
              paddingRight='1rem'
              flex={1}
              justifyContent='flex-end'
              alignItems='center'
              spacing='0.5rem'
            >
              <Button
                variant='outlined'
                sx={styles.cancelButton}
                onClick={onClose}
              >
                <Typography style={styles.cancelButtonLabel}>Cancel</Typography>
              </Button>
              <LoadingButton
                disabled={versionName.length === 0}
                variant='contained'
                sx={{...styles.saveButton}}
                onClick={renameVersion}
                loading={false}
                loadingIndicator={
                  <Loading
                    loading={false}
                    loadingProps={{color: BLACK_100}}
                  />
                }
              >
                <Typography style={styles.saveButtonLabel}>Save</Typography>
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

RenameVersionModal.propTypes = {
  ydoc: PropTypes.object.isRequired,
  editor: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  versionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default RenameVersionModal;
