import React, {useState, useEffect, useRef, useCallback} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux';
import {withRequiredAuthInfo} from '@propelauth/react';
import PropTypes from 'prop-types';

// Context
import {useDrawer} from '../../context/DrawerContext';

// Custom Components
import Drawer from '../../components/copilot/Drawer';
import DocumentEditor from './DocumentEditor';

// Collaboration
import {useRoom} from '../../liveblocks.config';
import {TiptapCollabProvider} from '@hocuspocus/provider'
import * as Y from 'yjs'

// PDF
import {useReactToPrint} from 'react-to-print';

// MUI
import {
  Box,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';

// Icons
import CheckIcon from '@mui/icons-material/Check';

// Stylesheets
import './DocumentStyles.css'
import '@liveblocks/react-comments/styles.css';

// Utils
import {useHighlightEventListener} from '../../components/report-workspace/comment-utils';
import axios from 'axios';
import {COPILOT_DRAWER_WIDTH, GREY_70} from '../../App'
import {BLACK_100, WHITE_100} from '../../theme';
import {hideActionSuccess, hideReviewComplete, hideActionError, setDocumentId as copilotSetDocumentId, reset as copilotReset} from '../../data/copilot';
import _ from 'lodash';
import {useParams} from 'react-router-dom';
import {withLDConsumer} from 'launchdarkly-react-client-sdk';
import {
  useGetDocumentQuery,
  useGetDocumentCollabTokenQuery,
  useMarkDocumentSavedMutation,
} from '../../data/documentWorkspaceApi';
import Error from '../../components/Error';
import NoData from '../../components/NoData';
import Loading from '../../components/Loading';
import {sleep, trackEvent} from '../../util';

const styles = {
  review: {
    container: {
      bgcolor: BLACK_100,
      borderRadius: '0.5rem',
    },
    header: {
      color: WHITE_100,
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    label: {
      color: WHITE_100,
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1.25rem',
    },
    boldLabel: {
      color: WHITE_100,
      fontSize: '0.75rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
  },
}

const putFileToS3 = async (presignedUrl, fileData) => {
  try {
    await axios.put(presignedUrl, fileData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.error('Error uploading file to S3:', error);
  }
};

const getFileFromS3 = async (presignedUrl) => {
  try {
    const response = await axios.get(presignedUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching document from S3:', error);
    throw error;
  }
};

const CollaborativeDocument = ({user, flags, ...props}) => {
  const {currentOrg} = useSelector((state) => state.session);
  const {documentId} = useParams();
  const room = useRoom();

  const shouldFetchToken = flags.collaborativeDocuments && !_.isNil(documentId) && !_.isNil(currentOrg);

  const {data: {token} = {}, isFetching, isError, isSuccess} = useGetDocumentCollabTokenQuery(
    {documentId},
    {
      skip: !shouldFetchToken,
      refetchOnMountOrArgChange: true,
    },
  );

  const providerToken = shouldFetchToken ? token : 'notoken';

  const [doc, setDoc] = useState();
  const [provider, setProvider] = useState();
  const documentName = `${currentOrg.orgId}:${documentId}`

  // Set up Tiptap Collaboration Yjs provider
  useEffect(() => {
    if (shouldFetchToken && (!isSuccess || !providerToken)) {
      console.log('Returning before creating doc and provider')
      return;
    }

    console.log('creating doc and provider')
    const yDoc = new Y.Doc();
    const yProvider = new TiptapCollabProvider({
      name: documentName,
      appId: process.env.REACT_APP_TIPTAP_APP_ID,
      document: yDoc,
      token: providerToken,
    });

    setDoc(yDoc);
    setProvider(yProvider);
  }, [room, documentName, isSuccess, providerToken, shouldFetchToken]);

  useEffect(() => {
    return () => {
      console.log('cleaning up doc and provider')
      provider?.disconnect();
      provider?.destroy();
      doc?.destroy();
      setDoc(null);
      setProvider(null);
    };
  }, []);

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if ((!doc || !provider) && !isFetching) {
    return <Error hasError={isError} message={'There was an error loading your document. Please try again later.'} />
  }

  return <Document {...props} flags={flags} provider={provider} doc={doc} />;
};

CollaborativeDocument.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  flags: PropTypes.shape({
    collaborativeDocuments: PropTypes.bool.isRequired,
  }),
};

const Document = ({provider, doc, accessHelper, flags}) => {
  const {documentId} = useParams();
  const {currentOrg} = useSelector((state) => state.session);
  const {documentId: copilotDocumentId} = useSelector((state) => state.copilot);
  const {copilotActionSuccess, copilotReviewComplete, copilotActionMessage, copilotActionError, copilotErrorMessage} = useSelector((state) => state.copilot);
  const dispatch = useDispatch();
  const canEdit = accessHelper.hasPermission(currentOrg.orgId, 'can_edit_reports');

  const {data: documentData, isFetching: documentIsFetching, isError: documentIsError, error: documentError, isSuccess: documentIsSuccess, refetch: documentRefetch} = useGetDocumentQuery({documentId}, {skip: _.isNil(documentId) || _.isNil(currentOrg), refetchOnMountOrArgChange: true});

  const [markDocumentSaved, {isError: documentSaveIsError, error: documentSaveError, reset: documentSaveReset}] = useMarkDocumentSavedMutation();
  const [saveFileUrl, setSaveFileUrl] = useState();
  const [content, setContent] = useState('<p></p>');

  // Manual loading and saving indicators
  const [isLoadingDocument, setIsLoadingDocument] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  // Drawers
  const {drawerStates, openDrawer, closeDrawer} = useDrawer();
  const copilot = useSelector(state => state.copilot);

  // Printing state
  const [isPrinting, setIsPrinting] = useState(false);
  const [isPrintContentReady, setIsPrintContentReady] = useState(false);
  const [isPreparingPrint, setIsPreparingPrint] = useState(false);

  // Refs
  const contentToPrintRef = useRef(null);
  const editorRef = useRef(null);

  if (_.isNil(documentId)) {
    // Redirect to documents page if documentId is not provided. Should never happen.
    this.props.history.replace('/documents');
  }

  const debouncedSetIsSavingFalse = useCallback(
    _.debounce(() => {
      setIsSaving(false);
    }, 3000),
    [],
  );

  const handleDocUpdate = useCallback(() => {
    console.log('Y Document updated.');
    setIsSaving(true);
    debouncedSetIsSavingFalse();
  }, [debouncedSetIsSavingFalse]);

  useEffect(() => {
    const handleSynced = () => {
      console.log('Document synced successfully.');
      doc.on('update', handleDocUpdate)
      setTimeout(() => setIsLoadingDocument(false), 500);
    };

    const handleStatus = ({status}) => {
      console.log('Connection status:', status);
      if (status === 'connected') {
        console.log('Connected to the collaboration provider.');
      }
    };

    provider.on('synced', handleSynced);
    provider.on('status', handleStatus);

    return () => {
      provider.off('synced', handleSynced);
      provider.off('status', handleStatus);
      doc.off('update', handleDocUpdate)
    };
  }, [provider, doc]);

  const _fetchDocument = async (signedUrl) => {
    if (signedUrl) {
      const _startTime = new Date().getTime();
      try {
        const fileData = await getFileFromS3(signedUrl);
        await deserializeDocument(fileData);
        trackEvent('Document Load Completed', {documentId}, new Date().getTime() - _startTime);
      } catch (error) {
        if (_.get(error, 'response.status') === 403) {
          await documentRefetch();
          trackEvent('Document Load Failed', {documentId, refetch: true}, new Date().getTime() - _startTime);
        } else {
          console.error('Error fetching or deserializing document:', error);
          trackEvent('Document Load Failed', {documentId, refetch: false}, new Date().getTime() - _startTime);
          setIsLoadingDocument(false);
        }
      }
    }
  };

  useEffect(() => {
    if (!documentIsFetching) {
      if (documentIsError) {
        if (documentError.status !== 401) {
          trackEvent('Document Metadata Load Failed', {documentId, status: documentError.status, refetch: false});
          setIsLoadingDocument(false);
        } else {
          trackEvent('Document Metadata Load Failed', {documentId, status: documentError.status, refetch: true});
          sleep(500).then(() => {
            documentRefetch();
          })
        }
      }
      if (documentIsSuccess) {
        if (!flags.collaborativeDocuments) {
          setSaveFileUrl(documentData.putSignedUrl);
          _fetchDocument(documentData.getSignedUrl);
        }

        if (documentId !== copilotDocumentId) {
          dispatch(copilotReset());
          dispatch(copilotSetDocumentId({documentId}));
        }
        trackEvent('Document Metadata Load Completed', {documentId});
      }
    }
  }, [documentIsSuccess, documentIsError, documentIsFetching]);

  useEffect(() => {
    if (documentSaveIsError) {
      console.error('Error marking document saved', {error: documentSaveError});
      trackEvent('Document Save Failed', {documentId, error: documentSaveError.status});
    }
  }, [documentSaveIsError]);

  const serializeDocument = useCallback((editor) => {
    const editorJSON = editor?.getJSON();

    return {
      json: editorJSON,
      text: accumulateTextByPage(editorJSON),
      tables: accumulateTablesByPage(editorJSON),
    };
  }, []);

  const saveDocument = useCallback(async (editor, retryCount = 3) => {
    const _startTime = new Date().getTime();
    setIsSaving(true);

    if (!saveFileUrl) {
      console.error('Error saving file, no url provided');
      trackEvent('Document Save Failed', {documentId, error: 'No URL provided'}, new Date().getTime() - _startTime);
      return;
    }

    try {
      const documentData = serializeDocument(editor);
      let signedUrl;

      await putFileToS3(saveFileUrl || signedUrl, JSON.stringify(documentData));
      await markDocumentSaved({documentId});
      documentSaveReset();
      trackEvent('Document Save Completed', {documentId}, new Date().getTime() - _startTime);
    } catch (error) {
      console.error('Error saving document:', error);

      trackEvent('Document Save Failed', {documentId, error: error.status, retryCount}, new Date().getTime() - _startTime);

      if (retryCount > 0) {
        setTimeout(() => saveDocument(editor, retryCount - 1), 2000);
      } else {
        console.error('Maximum retries reached, unable to save document.');
        setIsSaving(false);
        alert('Something went wrong saving your document. Please refresh your page and try again.');
      }
    } finally {
      setIsSaving(false);
    }
  }, [saveFileUrl, serializeDocument]);

  const deserializeDocument = async (fileData) => {
    if (!(typeof fileData === 'object' && 'json' in fileData && 'text' in fileData)) {
      setIsLoadingDocument(false);
    }

    const {json: editorJSON} = fileData;
    setContent(editorJSON);
    setIsLoadingDocument(false);
  };

  const accumulateTextByPage = (json) => {
    const result = [];

    const extractText = (content) => {
      let text = '';
      if (Array.isArray(content)) {
        for (const node of content) {
          if (node.type === 'text') {
            text += node.text;
          } else if (node.content) {
            text += extractText(node.content);
          }
        }
      }
      return text;
    }

    if (json && Array.isArray(json.content)) {
      for (const page of json.content) {
        if (page.type === 'page') {
          const pageText = extractText(page.content);
          result.push({page: page.attrs.pageNumber, text: pageText});
        }
      }
    }

    return result;
  };

  const accumulateTablesByPage = (json) => {
    const result = [];

    const extractTables = (content) => {
      const tables = [];
      if (Array.isArray(content)) {
        for (const node of content) {
          if (node.type === 'table') {
            const id = node.attrs.id;
            const tableData = JSON.parse(node.attrs['table-data'].replace(/\\'/g, "'"));
            const tableMeta = JSON.parse(node.attrs['table-meta']);
            tables.push({id, tableData, tableMeta});
          }
        }
      }
      return tables;
    };

    if (json && Array.isArray(json.content)) {
      for (const page of json.content) {
        if (page.type === 'page') {
          const tables = extractTables(page.content);
          result.push({page: page.attrs.pageNumber, tables});
        }
      }
    }

    return result;
  };

  useHighlightEventListener((highlightId, isClick) => {
    if (isClick) openDrawer('comments');
  });

  const enterPrintMode = useCallback(() => {
    setIsPreparingPrint(true);

    // Remove two lines below once fully migrated to collaborativeDoc
    const latestContent = editorRef.current ? editorRef.current.getJSON() : content;
    setContent(latestContent);

    setTimeout(() => setIsPrinting(true), 100);
  }, []);

  const exitPrintMode = useCallback(() => {
    setIsPrinting(false);
    setIsPreparingPrint(false);
    setIsPrintContentReady(false);
  }, []);

  const handlePrint = useReactToPrint({
    documentTitle: _.get(documentData, 'record.name'),
    removeAfterPrint: true,
    content: () => contentToPrintRef.current,
    onAfterPrint: exitPrintMode,
  });

  const onPrintContentReady = useCallback(() => {
    setIsPrintContentReady(true);
  }, []);

  useEffect(() => {
    if (isPrinting && isPrintContentReady) {
      setIsPreparingPrint(false);
      handlePrint();
    }
  }, [isPrinting, isPrintContentReady, handlePrint]);

  const _getErrorState = () => {
    switch (documentError.status) {
      case 404:
        return <NoData hidden={!documentIsError} screen={'document'} />
      default:
        return <Error hasError={documentIsError} message={'There was an error loading your document. Please try again later.'} />
    }
  }

  return (
    <Stack direction='column' width='100%' height='100vh' bgcolor={GREY_70}>
      <Loading
        loading={isLoadingDocument || isPreparingPrint}
        message={isLoadingDocument ? 'Loading report' : 'Preparing PDF for print'}
        loadingProps={{size: '46'}}
        containerProps={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: GREY_70,
          zIndex: 1000,
          pt: '45px',
        }}
      />

      {!isLoadingDocument && documentIsError && (
        <Box display={'flex'} flex={1} height={'100%'} alignItems={'center'}>
          {_getErrorState()}
        </Box>
      )}

      {!isLoadingDocument && !documentIsError &&
        <React.Fragment>
          <DocumentEditor
            editorRef={editorRef}
            provider={provider}
            doc={doc}
            canEdit={canEdit}
            content={content}
            contentToPrintRef={contentToPrintRef}
            isPrinting={isPrinting}
            isSaving={isSaving}
            onPrintContentReady={onPrintContentReady}
            saveDocument={saveDocument}
            enterPrintMode={enterPrintMode}
            flags={flags}
          />
          <Drawer
            onClose={() => closeDrawer('copilot')}
            open={drawerStates.copilot}
            flags={flags}
          />
        </React.Fragment>
      }

      <Snackbar
        open={copilotActionSuccess}
        autoHideDuration={5000}
        onClose={() => dispatch(hideActionSuccess())}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{paddingRight: drawerStates.copilot ? COPILOT_DRAWER_WIDTH : 0}}
      >
        <Stack direction={'row'} sx={{backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} height={'3rem'} paddingX={'1rem'} alignItems={'center'}>
          <Box marginRight={'0.75rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'0.25rem'} height={'1.25rem'} width={'1.25rem'} borderRadius={'50%'} sx={{background: 'linear-gradient(0deg, #33B980 0%, #33B980 100%), linear-gradient(180deg, rgba(49, 197, 125, 0.70) 0%, #3B768B 100%), #F1F4F2;'}}>
            <CheckIcon fontSize={'12px'} sx={{color: WHITE_100}}/>
          </Box>
          <Typography variant='body1'>{!_.isNil(copilotActionMessage) ? copilotActionMessage : 'Item resolved and closed'}</Typography>
        </Stack>
      </Snackbar>
      <Snackbar
        open={copilotActionError}
        autoHideDuration={5000}
        onClose={() => dispatch(hideActionError())}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        sx={{paddingRight: drawerStates.copilot ? COPILOT_DRAWER_WIDTH : 0}}
      >
        <Stack direction={'row'} sx={{backgroundColor: WHITE_100, borderRadius: '0.5rem', boxShadow: '0px 1px 5px 0px rgba(11, 14, 12, 0.15);'}} width={'16.25rem'} height={'3rem'} paddingX={'1rem'} alignItems={'center'}>
          <Box marginRight={'0.75rem'} display={'flex'} justifyContent={'center'} alignItems={'center'} padding={'0.25rem'} height={'1.25rem'} width={'1.25rem'} borderRadius={'50%'} sx={{background: 'linear-gradient(0deg, #33B980 0%, #33B980 100%), linear-gradient(180deg, rgba(49, 197, 125, 0.70) 0%, #3B768B 100%), #F1F4F2;'}}>
            <CheckIcon fontSize={'12px'} sx={{color: WHITE_100}}/>
          </Box>
          <Typography variant='body1'>{!_.isNil(copilotErrorMessage) ? copilotErrorMessage : 'There was an error processing your request. Please try again later.'}</Typography>
        </Stack>
      </Snackbar>
      <Snackbar
        open={copilotReviewComplete}
        autoHideDuration={5000}
        onClose={() => dispatch(hideReviewComplete())}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        sx={{paddingTop: '8rem'}}
      >
        <Stack direction={'column'} spacing={'0.125rem'}>
          <Typography style={styles.review.header}>Review complete!</Typography>
          <Stack direction={'row'}><Typography style={styles.review.label}>You have&nbsp;</Typography><Typography style={styles.review.boldLabel}>{copilot.numOpenItems} items</Typography><Typography style={styles.review.label}>&nbsp;to resolve.</Typography></Stack>
        </Stack>
      </Snackbar>
    </Stack>
  );
}

Document.propTypes = {
  session: PropTypes.object.isRequired,
  accessHelper: PropTypes.shape({
    hasPermission: PropTypes.func,
  }).isRequired,
  flags: PropTypes.shape({
    collaborativeDocuments: PropTypes.bool.isRequired,
  }),
  provider: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
  }
}

export default withLDConsumer()(connect(mapStateToProps)(withRequiredAuthInfo(CollaborativeDocument)));
