export const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  const month = new Intl.DateTimeFormat('en-US', {month: 'short'}).format(date);
  const day = new Intl.DateTimeFormat('en-US', {day: '2-digit'}).format(date);

  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return `${month} ${day}, ${hours}:${minutes} ${ampm}`;
}

export const insertYDocVersionMeta = (ydoc, versionId, key, value) => {
  ydoc.transact(() => {
    const versionsArray = ydoc.getArray('__tiptapcollab__versions');
    const versionIndex = versionsArray.toArray().findIndex((version) => version.version === versionId);
    if (versionIndex !== -1) {
      const version = versionsArray.get(versionIndex);
      version[key] = value;
      versionsArray.delete(versionIndex, 1);
      versionsArray.insert(versionIndex, [version]);
    }
  })
}

export const insertYDocUserMeta = (provider, versionId, userName) => {
  const versionsArray = provider.configuration.document.getArray('__tiptapcollab__versions');
  const versionIndex = versionsArray.toArray().findIndex((version) => version.version === versionId);
  if (versionIndex !== -1) {
    provider.configuration.document.getArray(`version_to_user_${versionIndex}`).push([String(userName)])
  }
}

// Hack to force a delta in yjs so we can always generate named versions
// This does not alter the outputted JSON content
export const touchEditor = (editor) => {
  editor.chain().focus().insertContent('\u200B').run();
};
